<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/multiply.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="items-container">
              <div class="row">
                <div class="col-md-4" id="item-menu">
                  <h5>Service</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td>{{ details.item_name }}</td>
                        <td>UGX {{ details.item_price | numberFormat }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <h5>Select Team Member</h5>
                  <select
                    v-model="team_member"
                    @change="getTeamAppointments()"
                    class="form-control"
                  >
                    <option value="default">select team</option>
                    <option
                      v-for="team in teams"
                      :key="team.id"
                      :value="team.id"
                    >
                      {{ team.name }}
                    </option>
                  </select>

                  <h5>Select date & Time</h5>
                  <p>{{ moment(date).format("DD - MMM - YYYY") }}</p>
                  <vue-timepicker
                    format="hh:mm A"
                    v-model="time"
                  ></vue-timepicker>
                  <!-- <date-picker
                    v-model="time"
                    :config="timeOptions"
                  ></date-picker> -->
                </div>

                <div class="col-md-6" id="item-details">
                  <label>Date</label>
                  <date-picker
                    v-model="date"
                    :config="options"
                    @dp-change="getTeamAppointments"
                  ></date-picker>
                  <!-- <SingleDatePicker /> -->

                  <br />
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea
                      v-model="appointment_notes"
                      class="form-control"
                      rows="3"
                      placeholder="Enter Appointment Notes"
                    >
                    </textarea>
                  </div>

                  <h3>
                    Bookings
                  </h3>
                  <p>
                    Here are the bookings that have been made for this selected
                    employee
                  </p>
                  <div v-if="isLoaded">
                    <LoadingSpinner />
                  </div>

                  <table>
                    <thead>
                      <tr>
                        <th>Time booked</th>
                        <th>Name</th>
                        <th>Service</th>
                        <th id="status">Complete</th>
                      </tr>
                    </thead>
                    <tbody v-if="team_member != 'default'">
                      <tr
                        v-for="appointment in team_appointments"
                        :key="appointment.id"
                      >
                        <td id="time">{{ appointment.time }}</td>
                        <td id="customer_name">
                          {{
                            appointment.first_name + " " + appointment.last_name
                          }}
                        </td>
                        <td id="service">{{ appointment.item_name }}</td>
                        <td id="status">
                          <div
                            v-if="appointment.appointment_status === 'Pending'"
                          >
                            <button class="btn btn-complete">Complete</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="4">Select team member</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div id="next">
                    <button class="btn btn-next" @click="updateAppointment">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
// import SingleDatePicker from "vue-single-date-picker";
// import moment from "moment";
import axios from "axios";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import "vue2-timepicker/dist/VueTimepicker.css";
import VueTimepicker from "vue2-timepicker";

export default {
  name: "success",
  components: {
    Navbar,
    // SingleDatePicker,
    datePicker,
    LoadingSpinner,
    VueTimepicker
  },
  props: ["details"],
  data() {
    return {
      isLoaded: false,
      teams: [],
      team_member: this.details.team_id,
      team_appointments: [],
      appointment_notes: this.details.appointment_notes,
      business_id: localStorage.getItem("business_id"),
      time: this.details.time,
      // timeOptions: {
      //   format: "h:mm:A",
      //   useCurrent: false
      // },
      date: this.details.date,
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        keepOpen: false
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getTeams() {
      axios
        .get(this.$hostname + "/getteams/" + this.business_id)
        .then(response => {
          this.teams = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getTeamAppointments() {
      this.isLoaded = true;
      const team_id = this.team_member;
      if (this.team_member != "default") {
        axios
          .get(
            this.$hostname +
              "/get-team-appointments?business_id=" +
              this.business_id +
              "&team_id=" +
              team_id +
              "&date=" +
              this.date
          )
          .then(response => {
            this.team_appointments = response.data.data;
            this.isLoaded = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoaded = false;
          });
      }
    },
    updateAppointment() {
      this.isLoaded = true;
      axios
        .put(this.$hostname + "/editappointment/" + this.details.id, {
          customer_id: this.details.customer_id,
          business_id: this.business_id,
          service_id: this.details.item_id,
          team_id: this.team_member,
          date: this.date,
          time: this.time,
          appointment_notes: this.appointment_notes
        })
        .then(response => {
          console.log(response.data.message);
          this.$emit("close");
          this.isLoaded = false;
          location.reload();
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
          alert("An error has occured");
        });
    }
  },
  mounted() {
    this.getTeams();
    this.getTeamAppointments();
    this.date = this.details.date.format("YYYY-MM-DD");
    this.time = this.details.time;
    // this.team_member = this.details.team_id;
  }
};
</script>

<style scoped>
@import "~vue-single-date-picker/dist/vue-single-date-picker.css";

.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
  padding-left: 20px;
}
.items-container {
  margin: 20px 20% 0px 20%;
}
#item-menu h5 {
  color: #707070;
  font-size: 17px;
  text-decoration: underline;
}
#item-menu p {
  font-size: 12px;
  color: #be2532;
  font-weight: bold;
}
#item-menu select {
  margin-bottom: 60px;
}
#item-menu table {
  margin-bottom: 50px;
}
#item-menu table tr {
  border: none;
  height: 20px;
}
#item-menu table tr td {
  color: #707070;
  font-size: 12px;
}
#item-menu table #total td {
  color: #22a860;
  border-top: 0.5px solid silver;
  font-weight: bold;
  font-size: 12px;
}
#item-details h3 {
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 28px;
}
#item-details p {
  color: #707070;
  font-size: 12px;
  width: 60%;
  margin-bottom: 30px;
}
#item-details table thead tr th {
  font-weight: bold;
  font-size: 12px;
}
#item-details table tbody tr td {
  font-size: 12px;
}
#item-details table thead #status,
#item-details table tbody #status {
  text-align: right;
}
#item-details .btn-complete {
  height: 28px;
  font-size: 12px;
  border: 1px solid #001637;
}
#next {
  /* margin-top: 60px; */
  position: relative;
  top: 90%;
  margin-bottom: 100px;
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
