<template>
  <div>
    <transition name="modal-fade">
      <form method="post" @submit.prevent="editTeam" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <section class="modal-header">
            <img src="@/assets/multiply.png" @click="close" />

            <span>Edit Team Member</span>

            <button type="submit" class="btn-save">
              Save
            </button>
          </section>
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <h4 class="sub-heading">Details</h4>

                  <div class="new-item">
                    <div class="r">
                      <label>Name</label>
                      <input
                        type="text"
                        v-model="teamDetails.name"
                        placeholder="Deluca"
                        required
                      />
                    </div>
                  </div>

                  <div class="new-item" style="margin-top: 50px">
                    <div class="r">
                      <label>Primary Job Title</label>
                      <input
                        type="text"
                        v-model="teamDetails.job_title"
                        placeholder="Store Manager"
                      />
                    </div>
                    <div class="r">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        v-model="teamDetails.phone_number"
                        placeholder="498 231 3282"
                      />
                    </div>
                    <div class="r">
                      <label>Email Address</label>
                      <input
                        type="email"
                        v-model="teamDetails.email_address"
                        placeholder="lucienne1991@hotmail.com"
                      />
                    </div>
                    <div class="r">
                      <label>Team Member ID</label>
                      <input
                        type="text"
                        v-model="teamDetails.team_id"
                        placeholder="850"
                      />
                    </div>
                    <div class="r">
                      <label>Pay Type</label>
                      <input
                        type="text"
                        v-model="teamDetails.pay_type"
                        placeholder="Commission"
                      />
                    </div>
                    <div class="r">
                      <label>Amount</label>
                      <input
                        type="text"
                        v-model="teamDetails.amount"
                        placeholder="30%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isLoading">
                <LoadingSpinner />
              </div>
            </slot>
          </section>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "newItem",
  components: { LoadingSpinner },
  props: ["teamDetails"],
  data: function() {
    return {
      business_id: localStorage.getItem("business_id"),
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    editTeam() {
      this.isLoading = true;
      axios
        .put(this.$hostname + "/editteam/" + this.teamDetails.id, {
          business_id: this.business_id,
          name: this.teamDetails.name,
          job_title: this.teamDetails.job_title,
          phone_number: this.teamDetails.phone_number,
          email: this.teamDetails.email_address,
          team_id: this.teamDetails.team_id,
          pay_type: this.teamDetails.pay_type,
          amount: this.teamDetails.amount
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isLoading = false;
          this.$router.go();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoading = false;
          alert("Error creating team member");
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-header {
  box-shadow: 0px 0px 6px #0000004d;
  padding: 20px;
}
.modal-header span {
  font-weight: bold;
  font-size: 20px;
}
.sub-heading {
  padding-left: 30px;
  margin-top: 50px;
  padding-bottom: 5px;
  font-size: 19px;
  font-weight: bold;
}

.new-item {
  white-space: nowrap;
  padding-left: 30px;
}

.r {
  margin-top: -10px;
}

label {
  background: #ebebeb;
  color: #3a3e46;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  height: 40px;
  width: 150px;
  vertical-align: top;
  display: inline-block;
  padding-top: 10px;
  padding-left: 20px;
  margin-right: -1px;
  font-weight: bold;
  font-size: 13px;
}
.r input {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-left: none;
  height: 40px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  padding-left: 20px;
}

.btn-save {
  color: white;
  background: #bd2532;
  border: 1px solid #bd2532;
  border-radius: 5px;
  font-weight: bold;
  width: 93px;
  height: 37px;
  float: right;
}
</style>
