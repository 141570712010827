<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="top">
                <img src="@/assets/multiply.png" @click="close" />
                <h4>
                  Time
                </h4>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="time"
                      required
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="row" id="time">
                  <div class="col-md-12">
                    <p>
                      {{ time }}
                      <button class="btn btn-done" @click="selectTime">
                        Done
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import "vue2-timepicker/dist/VueTimepicker.css";
import VueTimepicker from "vue2-timepicker";

export default {
  components: {
    VueTimepicker
  },
  data() {
    return {
      time: ""
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectTime() {
      this.$emit("selectTime", this.time);
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  border-radius: 0;
  height: 400px;
  width: 600px;
}
.top img {
  float: left;
  padding: 33px 0px 0px 34px;
}
.top h4 {
  text-align: center;
  margin-top: 54px;
  color: #37383b;
  font-size: 23px;
  font-weight: bold;
}
#time {
  margin-top: 180px;
}
.btn-done {
  color: #be2532;
  font-weight: bold;
}
@media (max-width: 768px) {
  .modal-content {
    height: 400px;
    width: 100%;
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
  }
}
</style>
