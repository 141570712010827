<template>
  <div class="home">
    <div class="row">
      <div class="col-4 col-md-4 col-lg-3">
        <div id="sidebar-wrapper">
          <Sidebar :routes="routes" />
        </div>
      </div>
      <div class="col-8 col-md-8 col-lg-9">
        <Upcoming />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/Layouts/Sidebar";
import Upcoming from "@/components/Appointments/Upcoming.vue";

export default {
  name: "Home",
  components: {
    Upcoming,
    Sidebar
  },
  data() {
    return {
      routes: [
        {
          link: "/upcoming",
          title: "Upcoming Appointments"
        },
        {
          link: "/appointment-requests",
          title: "Appointment Requests"
        },
        {
          link: "/history",
          title: "History"
        }
      ]
    };
  }
};
</script>
