<template>
  <div>
    <div class="items">
      <div class="row page-heading">
        <div class="col-md-12 col-xs-12">
          <h4>Items</h4>
        </div>
      </div>

      <div class="row locations-menu">
        <div class="col-md-3 col-xs-12">
          <select
            v-model="category_id"
            @change="getCategoryItems($event)"
            class="form-control"
          >
            <option value="all">All Categories</option>
            <option
              v-for="category in categories"
              :value="category.id"
              :key="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 col-xs-12">
          <select
            v-model="selectedLocation"
            @change="getLocationItems($event)"
            class="form-control"
          >
            <option value="all">All Locations</option>
            <option
              v-for="location in locations"
              :value="location.id"
              :key="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 col-xs-12"></div>
        <div class="col-md-3 col-xs-12 new-item-button">
          <button class="btn btn-danger" @click="createItem = true">
            Create an item
          </button>
          <create-item
            :categories="categories"
            :locations="locations"
            v-show="createItem"
            @close="createItem = false"
          />
        </div>
      </div>

      <div v-if="isLoading">
        <LoadingSpinner />
      </div>

      <div class="row items-table">
        <div class="col-md-12 col-xs-12">
          <table>
            <thead>
              <tr>
                <th class="item">Item</th>
                <th>Category</th>
                <th>Locations</th>
                <th class="price">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="item">{{ item.name }}</td>
                <td>{{ item.category_name }}</td>
                <td>
                  <div v-if="item.location_id === 0">
                    HQ
                  </div>
                  <div v-else>
                    {{ item.location_name }}
                  </div>
                </td>
                <td class="price">{{ item.price | numberFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateItem from "./Create";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "items",
  components: { LoadingSpinner, CreateItem },
  data() {
    return {
      business_id: localStorage.getItem("business_id"),
      category_id: "all",
      selectedLocation: "all",
      locations: [
        {
          id: 0,
          name: "HQ"
        }
      ],
      items: [],
      categories: [],
      isLoading: false,
      createItem: false
    };
  },
  methods: {
    getItems() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getitems/" + this.business_id)
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
          alert("An error has occured retrieving items");
        });
    },
    getCategories() {
      axios
        .get(this.$hostname + "/getcategories/" + this.business_id)
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(error => {
          error;
          alert("An error has occured retrieving categories");
        });
    },
    getCategoryItems(event) {
      this.isLoading = true;
      const category = event.target.value;
      this.items = [];
      if (event.target.value === "all") {
        var url = this.$hostname + "/getitems/" + this.business_id;
      } else {
        url = this.$hostname + "/getcategoryitems/" + category;
      }
      axios
        .get(url)
        .then(response => {
          this.items = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          error;
          this.isLoading = false;
          alert("Error retrieving items");
        });
    },
    getLocations() {
      //
    },
    getLocationItems(event) {
      event.target.value;
    }
  },
  mounted() {
    this.getItems();
    this.getCategories();
  }
};
</script>

<style scoped>
.page-heading h4 {
  font-weight: bold;
  font-size: 30px;
}
.locations-menu {
  margin-top: 30px;
  margin-bottom: 20px;
}
.new-item-button button {
  float: right;
}
.btn-danger,
.btn-danger:hover {
  color: #ffffff;
  font-weight: bold;
  background-color: #bd2532;
}

table .item {
  width: 40%;
  padding-left: 30px;
}

table tbody .item {
  color: #bd2532;
}

table .price {
  text-align: right;
  width: 20%;
  padding-right: 10px;
}
</style>
