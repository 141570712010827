<template>
  <div>
    <transition name="modal-fade">
      <form method="post" @submit.prevent="createAppointment" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <Navbar />
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="heading">
                <img
                  src="@/assets/left-arrow.png"
                  class="btn-close"
                  @click="close"
                />
              </div>

              <div id="details-container">
                <div class="row" id="customer_no">
                  <div class="col-md-12">
                    <h3>Customer Details</h3>
                    <div class="form-group">
                      <label>Phone No *</label>
                      <br />
                      <input
                        type="text"
                        v-model="customer_details.phone_number"
                        class="form-control"
                        placeholder="Phone Number"
                      />
                      <p>Customer Phone number</p>
                    </div>
                  </div>
                </div>
                <div class="row" id="customer_details">
                  <div class="col-md-12">
                    <div v-if="isLoaded">
                      <LoadingSpinner />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <span>Names *</span>
                    <br />
                    <p>
                      {{
                        customer_details.first_name +
                          " " +
                          customer_details.last_name
                      }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <span>Gender *</span>
                    <br />
                    <p>{{ customer_details.sex }}</p>
                  </div>
                  <div class="col-md-6">
                    <span>Date Of Birth *</span>
                    <br />
                    <p>
                      <!-- {{
                        moment(customer_details.date_of_birth).format(
                          "DD / MM / YYYY"
                        )
                      }} -->
                      {{ customer_details.date_of_birth }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <span>Email *</span>
                    <br />
                    <p>{{ customer_details.email }}</p>
                  </div>
                </div>

                <div class="row" id="book-appointment">
                  <div class="col-md-12">
                    <button class="btn btn-book" type="submit">
                      Book Appointment
                    </button>
                  </div>
                </div>

                <Success
                  v-if="completeBooking"
                  @close="completeBooking = false"
                />
              </div>
            </slot>
          </section>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import Success from "./Success.vue";
import Axios from "axios";
// import moment from "moment";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "success",
  components: {
    Navbar,
    Success,
    LoadingSpinner
  },
  props: [
    "team_id",
    "date",
    "time",
    "appointment_notes",
    "service_id",
    "customer_details"
  ],
  data() {
    return {
      isLoaded: false,
      completeBooking: false,
      business_id: localStorage.getItem("business_id")
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createAppointment() {
      this.isLoaded = true;
      Axios.post(this.$hostname + "/createappointment", {
        customer_id: this.customer_details.id,
        business_id: this.business_id,
        service_id: this.service_id,
        team_id: this.team_id,
        appointment_notes: this.appointment_notes,
        date: this.date,
        time: this.time
      })
        .then(response => {
          console.log(response.data.message);
          console.log("success");
          this.completeBooking = true;
          this.isLoaded = false;
        })
        .catch(error => {
          this.isLoaded = false;
          alert("an error occured, please try again");
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
  text-align: left;
}
#details-container {
  margin: 20px 25% 0px 25%;
  text-align: left;
}
h3 {
  color: #3a3e46;
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 44px;
}
label {
  color: #697478;
  font-weight: bold;
  font-size: 16px;
}
input {
  height: 49px;
  margin-bottom: 11px;
}
#customer_no p {
  color: #697478;
  font-size: 10px;
  margin-bottom: 60px;
  text-align: left;
}
#customer_details {
  border-top: 0.5px solid silver;
  border-bottom: 0.5px solid silver;
  opacity: 1;
  padding: 10px 0px 20px 0px;
  margin-bottom: 90px;
}
#customer_details span {
  color: #697478;
  font-size: 12px;
}
#customer_details p {
  color: #697478;
  font-size: 16px;
  font-weight: bold;
}
#book-appointment {
  text-align: center;
}
.btn-book {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  height: 49px;
  margin-bottom: 100px;
}
</style>
