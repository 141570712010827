<template>
  <div>
    <div class="items">
      <div class="row page-heading">
        <div class="col-md-12 col-xs-12">
          <h4>Disabled Categories & Items</h4>
        </div>
      </div>

      <div class="row item-type-menu">
        <div class="col-md-3 col-xs-12">
          <select
            v-model="selected"
            @change="changeOption($event)"
            class="form-control"
          >
            <option value="all">All Item Types</option>
            <option value="categories">Categories</option>
            <option value="items">Items</option>
          </select>
        </div>
        <div class="col-md-6 col-xs-12">
          <input
            name="search"
            class="form-control"
            placeholder="Search All Items"
          />
        </div>
      </div>
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div class="row items-table">
        <div class="col-md-12 col-xs-12">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Item Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in disabledItems" :key="item.id">
                <td>{{ item.name }}</td>
                <td>Category</td>
                <td>
                  <img src="../../assets/disabledToggle.svg" width="30px" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner
  },
  data() {
    return {
      selected: "all",
      disabledItems: [],
      business_id: localStorage.getItem("business_id"),
      isLoading: false
    };
  },
  methods: {
    disabledItemCategories() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/disabledcategories/" + this.business_id)
        .then(response => {
          this.disabledItems = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          error;
          alert("An error has occured retrieving categories");
        });
    },
    changeOption(event) {
      if (event.target.value === "all") {
        this.disabledItems = [];
        this.disabledItemCategories();
      } else if (event.target.value === "categories") {
        this.disabledItems = [];
        this.disabledItemCategories();
      } else {
        this.disabledItems = [];
      }
    }
  },
  mounted() {
    this.disabledItemCategories();
  }
};
</script>

<style scoped>
.items {
  padding: 30px;
}
.page-heading h4 {
  font-weight: bold;
  font-size: 25px;
}
.item-type-menu {
  margin-top: 30px;
  margin-bottom: 20px;
}
tr th:nth-child(2),
tr th:nth-child(2),
tr td:nth-child(2),
tr td:nth-child(2) {
  text-align: right;
}
</style>
