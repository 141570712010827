<template>
  <div class="container">
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div id="header">
              <div class="row">
                <div class="col-12 col-md-12">
                  <img src="@/assets/left-arrow.png" @click="close" />
                </div>
              </div>
            </div>

            <div id="content">
              <div class="row">
                <div class="col-12 col-md-12">
                  <h3>More about your business.</h3>
                </div>
              </div>

              <form @submit.prevent="createBusiness">
                <div class="row">
                  <div class="col-12 col-md-6 form-group">
                    <label>What is your business name?</label>
                    <input
                      v-model="business_details.business_name"
                      type="text"
                      class="form-control"
                      placeholder="Business name..."
                      required
                    />
                    <p>
                      If you do not have an official business name, enter the
                      name you want on your receipts.
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 form-group">
                    <label>Business contact</label>
                    <input
                      v-model="business_details.first_name"
                      type="text"
                      class="form-control"
                      placeholder="Your first name"
                      required
                    />
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label id="hidden">.</label>
                    <input
                      v-model="business_details.last_name"
                      type="text"
                      class="form-control"
                      placeholder="Your last name"
                      required
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 form-group">
                    <label>Business address</label>
                    <input
                      v-model="business_details.business_address"
                      type="text"
                      class="form-control"
                      placeholder="Address 1"
                      required
                    />
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label>City</label>
                    <input
                      v-model="business_details.business_city"
                      type="text"
                      class="form-control"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-12" id="button">
                    <button type="submit" class="btn btn-continue">
                      Continue
                    </button>
                  </div>
                </div>
                <div id="spinner" v-if="isLoading">
                  <LoadingSpinner />
                </div>
                <Success v-if="showSuccess" @close="showSuccess = false" />
              </form>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import Success from "@/components/Auth/registerComponents/Success";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "MoreDetails",
  components: {
    Success,
    LoadingSpinner
  },
  props: ["user_data", "business_type", "payment_type"],
  data() {
    return {
      business_details: {
        business_name: "",
        first_name: "",
        last_name: "",
        business_address: "",
        business_city: ""
      },
      showSuccess: false,
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createBusiness() {
      this.isLoading = true;
      axios
        .post(this.$hostname + "/createBusiness", {
          name: this.business_details.business_name,
          address: this.business_details.business_address,
          phone_number: this.user_data.phone_number,
          pin: this.user_data.password,
          email: this.user_data.email,
          country_id: this.user_data.country,
          city: this.business_details.business_city,
          industry_type_id: this.business_type,
          payment_type: this.payment_type,
          contact_firstname: this.business_details.first_name,
          contact_lastname: this.business_details.last_name
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isLoading = false;
          this.showSuccess = true;
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status === 500) {
            alert("Error! Phone Number already exists");
          } else {
            alert("An error has occured");
          }
        });
    }
  }
};
</script>

<style scoped>
#spinner {
  left: 50%;
}
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
#header {
  margin-top: 30px;
  margin-bottom: 25px;
  color: #3d454c;
}
#content {
  padding: 30px 100px 100px 100px;
  color: #3d454c;
}
#content h3 {
  font-size: 26px;
  margin-bottom: 45px;
}
#content label {
  font-weight: bold;
  font-size: 12px;
}
#content #hidden {
  visibility: hidden;
}
#content p {
  font-size: 12px;
  margin-top: 9px;
}
#content input {
  border: 1px solid #707070;
}
.btn-continue {
  width: 50%;
  height: 41px;
  background: #be2532;
  color: #ffffff;
  border-radius: 6px;
}
#button {
  text-align: center;
  margin-top: 152px;
}
@media (max-width: 768px) {
  #content {
    padding: 30px 20px 20px 20px;
  }
  #button {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
