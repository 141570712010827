<template>
  <div>
    <div class="categories">
      <div class="row page-heading">
        <div class="col-md-12 col-xs-12">
          <h4>Categories</h4>
        </div>
      </div>

      <div class="row subheading">
        <div class="col-md-8 col-xs-12">
          <p>
            Categories help you arrange and organize your items, report on item
            sales, and route items to specific printers.
          </p>
        </div>

        <div class="col-md-1 col-xs-12"></div>

        <div class="col-md-3 col-xs-12 new-category-button">
          <button class="btn btn-danger" @click="isCreateCategory = true">
            Create a Category
          </button>

          <create-category
            v-show="isCreateCategory"
            @close="isCreateCategory = false"
          />

          <rename
            v-show="isRenameCategory"
            @close="isRenameCategory = false"
            :categoryDetails="selectedCategory"
          />
          <delete
            v-show="isDeleteCategory"
            @close="isDeleteCategory = false"
            :categoryDetails="selectedCategory"
          />
        </div>
      </div>
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div class="row category-table">
        <div class="col-md-12 col-xs-12">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="category in categories" :key="category.id">
                <td>{{ category.name }}</td>
                <td class="elipsis">
                  <button
                    type="button"
                    class="btn btn-success dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img src="@/assets/elipsis.svg" width="30px" />
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" role="menu">
                    <li>
                      <a href="#" @click="renameCategory(category)">Rename</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        @click="deleteCategory(category)"
                        style="color: #BD2532"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateCategory from "./Create";
import Delete from "./Delete";
import Rename from "./Rename";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  props: ["categories", "isLoading"],
  components: {
    CreateCategory,
    Delete,
    Rename,
    LoadingSpinner
  },
  data() {
    return {
      isCreateCategory: false,
      isDeleteCategory: false,
      isRenameCategory: false,
      selectedCategory: "",
      business_id: localStorage.getItem("business_id")
    };
  },
  methods: {
    renameCategory(category) {
      this.isRenameCategory = true;
      this.selectedCategory = category;
    },
    deleteCategory(category) {
      this.isDeleteCategory = true;
      this.selectedCategory = category;
    }
  }
};
</script>

<style scoped>
.categories {
  padding: 20px;
}
.page-heading {
  margin-bottom: 20px;
}
.page-heading h4 {
  font-weight: bold;
  font-size: 30px;
}
.btn-danger,
.btn-danger:hover {
  color: #ffffff;
  font-weight: bold;
  background-color: #bd2532;
  width: 100%;
  height: 37px;
}
.elipsis {
  text-align: right;
  width: 20%;
}
.btn-success,
.btn-success:focus,
.btn-success:active,
.btn-success:hover {
  background-color: #ffffff;
  border: none;
  border-color: #ffffff;
}
.dropdown-menu {
  width: 50px !important;
  padding-left: 20px;
  font-weight: bold;
}
</style>
