<template>
  <div class="container">
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div id="header">
              <div class="row">
                <div class="col-12 col-md-12">
                  <img src="@/assets/left-arrow.png" @click="close" />
                </div>
              </div>
            </div>

            <div id="content">
              <div class="row" id="head">
                <div class="col-md-12">
                  <h2>Tell us about your business.</h2>
                  <p>
                    We want to make you know exactly how Yammzit - iKO can meet
                    your business’s unique needs. Fill out the information below
                    so we can provide specific information about how Yamzit can
                    work for your business.
                  </p>
                </div>
              </div>

              <form @submit.prevent="proceed()">
                <div class="row">
                  <div class="col-12 col-md-6 form-group">
                    <label>What type of business is it?</label>
                    <select
                      class="form-control"
                      v-model="business_type"
                      required
                    >
                      <option value="">Please Select...</option>
                      <option
                        v-for="industry in business_types"
                        :key="industry.id"
                        :value="industry.id"
                      >
                        {{ industry.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 form-group">
                    <label>Which of the digital payments do you accept?</label>
                    <p style="display: block;">
                      <input
                        v-model="payment_type"
                        value="MobileMoney"
                        type="radio"
                        class="form-control"
                        style="display: inline-block"
                        required
                      />
                      Mobile only
                    </p>
                    <p style="display: block;">
                      <input
                        v-model="payment_type"
                        value="Card"
                        type="radio"
                        class="form-control"
                        style="display: inline-block"
                        required
                      />
                      Card payments
                    </p>
                    <p style="display: block;">
                      <input
                        v-model="payment_type"
                        value="Both"
                        type="radio"
                        class="form-control"
                        style="display: inline-block"
                        required
                      />
                      Both
                    </p>
                    <p style="display: block;">
                      <input
                        v-model="payment_type"
                        value="None"
                        type="radio"
                        class="form-control"
                        style="display: inline-block"
                        required
                      />
                      None of the above
                    </p>
                  </div>
                </div>

                <div class="col-12 col-md-12 form-group" id="button">
                  <button type="submit" class="btn btn-continue">
                    Continue
                  </button>
                </div>
                <MoreAboutBusiness
                  v-if="showMoreAboutBusiness"
                  @close="showMoreAboutBusiness = false"
                  :user_data="user_data"
                  :business_type="business_type"
                  :payment_type="payment_type"
                />
              </form>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import MoreAboutBusiness from "@/components/Auth/registerComponents/MoreAboutBusiness";

export default {
  name: "businessdetails",
  components: {
    MoreAboutBusiness
  },
  props: ["business_types", "user_data"],
  data() {
    return {
      business_type: "",
      payment_type: "None",
      showMoreAboutBusiness: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    proceed() {
      this.showMoreAboutBusiness = true;
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
#header {
  margin-top: 30px;
  margin-bottom: 25px;
  color: #3d454c;
}
#content {
  padding: 30px 150px 150px 150px;
  color: #3d454c;
}
#content #head {
  margin-bottom: 51px;
}
#content h2 {
  font-size: 26px;
}
#content p {
  font-size: 16px;
}
#content label {
  font-size: 12px;
  font-weight: bold;
}
#content select {
  border: 1px solid #707070;
  margin-bottom: 61px;
}
#content input {
  height: 14px;
  width: 14px;
  border: 1px solid #3a3e46;
}
#button {
  text-align: center;
  margin-top: 50px;
}
.btn-continue {
  width: 50%;
  height: 41px;
  background: #be2532;
  color: #ffffff;
  border-radius: 6px;
}
@media (max-width: 768px) {
  #content {
    padding: 40px 20px 20px 20px;
  }
}
</style>
