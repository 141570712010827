<template>
  <div>
    <div v-if="visible" id="customer_details">
      <h3>Details</h3>
      <hr />
      <form @submit.prevent="makeAppointment">
        <div class="row">
          <div class="col-md-6">
            <p>Names *</p>
            <h4>{{ customer.first_name }} {{ customer.last_name }}</h4>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <p>Service</p>
            <h4>{{ item.name }}</h4>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <p>Date *</p>
            <h4>{{ moment(date).format("d / MMM / Y") }}</h4>
          </div>
          <div class="col-md-6">
            <p>Time *</p>
            <h4>{{ time }}</h4>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button type="submit" class="btn btn-book">
              Next
            </button>
          </div>
        </div>
        <div id="spinner" v-if="isLoading">
          <LoadingSpinner />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "customer-details",
  components: {
    LoadingSpinner
  },
  props: {
    item: {
      type: Object
    },
    date: {
      type: String
    },
    team: {
      type: Object
    },
    time: {
      type: String
    },
    customer: {
      type: Object
    },
    businessDetails: {
      type: Object
    }
  },
  data() {
    return {
      visible: true,
      isLoading: false
    };
  },
  methods: {
    makeAppointment() {
      this.isLoading = true;
      axios
        .post(this.$hostname + "/createappointment", {
          business_id: this.businessDetails.business.id,
          customer_id: this.customer.id,
          service_id: this.item.id,
          team_id: this.team.id,
          date: this.date,
          time: this.time,
          onhold: "Onhold"
        })
        .then(response => {
          response;
          this.isLoading = false;
          this.$router.push({
            name: "CompeleteBooking",
            params: { business_name: this.businessDetails.business_url }
          });
        })
        .catch(error => {
          error;
          alert("An error occurred while making appointment");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#book-activity #customer_details h3 {
  color: #37383b;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 27px;
}
#book-activity #customer_details p {
  text-align: left;
  color: #697478;
  font-size: 12px;
}
#book-activity #customer_details h4 {
  color: #697478;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
</style>
