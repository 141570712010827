<template>
  <div class="appointment_history">
    <div class="row" id="header">
      <div class="col-md-12">
        <h2>History</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <date-range-picker
          ref="picker"
          :opens="opens"
          :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
          :minDate="minDate"
          :maxDate="maxDate"
          :autoApply="autoApply"
          v-model="dateRange"
          @update="getAppointments"
        >
          <template v-slot:input="picker">
            {{ moment(picker.startDate).format("MMM DD, YYYY") }} -
            {{ moment(picker.endDate).format("MMM DD, YYYY") }}
          </template>
        </date-range-picker>
      </div>
    </div>

    <div v-if="isLoaded">
      <LoadingSpinner />
    </div>

    <div class="row" id="data">
      <div class="col-md-12">
        <div v-if="allappointments.length > 0">
          <table>
            <tbody v-for="(up, index) in allappointments" :key="up.id">
              <tr id="date_row">
                <td style="width: 250px">
                  {{ moment(up.date).format("dddd, MMMM DD, YYYY") }}
                </td>
                <td style="width: 150px"><div v-if="index == 0">Name</div></td>
                <td><div v-if="index == 0">Phone Number</div></td>
                <td><div v-if="index == 0">Service</div></td>
                <td><div v-if="index == 0">Employee</div></td>
                <td><div v-if="index == 0">Notes</div></td>
                <td style="width: 100px">
                  <div v-if="index == 0">Complete</div>
                </td>
              </tr>
              <tr v-for="appointment in up.appointments" :key="appointment.id">
                <td>{{ appointment.time }}</td>
                <td>
                  {{ appointment.first_name + " " + appointment.last_name }}
                </td>
                <td>{{ appointment.phone_number }}</td>
                <td>{{ appointment.item_name }}</td>
                <td>{{ appointment.team_name }}</td>
                <td>{{ appointment.appointment_notes }}</td>
                <td>
                  <div v-if="appointment.appointment_status == 'Pending'">
                    <span id="nc">
                      Not complete
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table>
            <tr id="date_row">
              <td style="width: 250px"></td>
              <td>Name</td>
              <td>Phone Number</td>
              <td>Service</td>
              <td>Employee</td>
              <td>Notes</td>
              <td>Complete</td>
            </tr>
            <tr>
              <td colspan="7">No results</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "AppointmentHistory",
  components: {
    DateRangePicker,
    LoadingSpinner
  },
  data() {
    return {
      allappointments: [],
      business_id: localStorage.getItem("business_id"),
      isLoaded: false, // displays the spinner
      //date range picker variables
      opens: "center",
      minDate: null,
      maxDate: null,
      singleDatePicker: false,
      showDropdowns: true,
      autoApply: true,
      dateRange: {
        startDate: moment().subtract(7, "days"),
        endDate: moment()
      }
    };
  },
  methods: {
    getAppointments() {
      this.isLoaded = true;
      axios
        .get(
          this.$hostname +
            "/get-appointment-history?business_id=" +
            this.business_id +
            "&start_date=" +
            moment(this.dateRange.startDate).format("YYYY-MM-DD") +
            "&end_date=" +
            moment(this.dateRange.endDate).format("YYYY-MM-DD")
        )
        .then(response => {
          this.allappointments = response.data.data;
          this.isLoaded = false;
        })
        .catch(error => {
          error;
          alert("An error has occured");
          this.isLoaded = false;
        });
    }
  },
  created() {
    this.getAppointments();
  }
};
</script>

<style scoped>
#header {
  margin-bottom: 60px;
}
h2 {
  font-weight: bold;
  font-size: 24px;
}
#data {
  margin-top: 20px;
}
table tbody tr td {
  font-size: 13px;
}
#date_row {
  background-color: #ebebeb;
  color: #001637;
  border-bottom: none;
  font-weight: bold;
  height: 40px;
}
#nc {
  color: #be2532;
  font-size: 12px;
}
</style>
