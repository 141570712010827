<template>
  <div>
    <transition name="modal-fade">
      <form method="post" @submit.prevent="createAppointment" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <Navbar />
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="heading">
                <img
                  src="@/assets/left-arrow.png"
                  class="btn-close"
                  style="float:left"
                  @click="close"
                />
              </div>

              <div class="items-container">
                <div class="row">
                  <div class="col-md-4" id="item-menu">
                    <h5>Service</h5>
                    <table>
                      <tbody>
                        <tr v-for="service in services" :key="service.id">
                          <td>{{ service.item.name }}</td>
                          <td>UGX {{ service.item.price | numberFormat }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <h5>Select Team Member</h5>
                    <select
                      v-model="team_member"
                      @change="getTeamAppointments()"
                      class="form-control"
                      required
                    >
                      <option value="">select team</option>
                      <option
                        v-for="team in teams"
                        :key="team.id"
                        :value="team.id"
                      >
                        {{ team.name }}
                      </option>
                    </select>

                    <h5>Select date & Time</h5>
                    <p>{{ moment(date).format("DD - MMM - YYYY") }}</p>
                    <div class="form-group" style="float: left">
                      <vue-timepicker
                        format="hh:mm A"
                        v-model="time"
                        required
                      ></vue-timepicker>
                    </div>
                    <!-- <date-picker
                    v-model="time"
                    :config="timeOptions"
                  ></date-picker> -->
                  </div>
                  <div class="col-md-6" id="item-details">
                    <div class="form-group">
                      <label>Date</label>
                      <date-picker
                        v-model="date"
                        :config="options"
                        @dp-change="getTeamAppointments"
                      ></date-picker>
                    </div>
                    <!-- <SingleDatePicker @selectDate="getDate()" /> -->

                    <div class="form-group">
                      <label>Notes</label>
                      <textarea
                        v-model="appointment_notes"
                        class="form-control"
                        rows="3"
                        placeholder="Enter Appointment Notes"
                      >
                      </textarea>
                    </div>

                    <h3>
                      Bookings
                    </h3>
                    <p>
                      Here are the bookings that have been made for this
                      selected employee
                    </p>
                    <div v-if="isLoaded">
                      <LoadingSpinner />
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th>Time booked</th>
                          <th>Name</th>
                          <th>Service</th>
                          <th id="status">Complete</th>
                        </tr>
                      </thead>
                      <tbody v-if="team_member != 'default'">
                        <tr
                          v-for="appointment in team_appointments"
                          :key="appointment.id"
                        >
                          <td id="time">{{ appointment.time }}</td>
                          <td id="customer_name">
                            {{
                              appointment.first_name +
                                " " +
                                appointment.last_name
                            }}
                          </td>
                          <td id="service">{{ appointment.item_name }}</td>
                          <td id="status">
                            <div
                              v-if="
                                appointment.appointment_status === 'Pending'
                              "
                            >
                              <button class="btn btn-complete">Complete</button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4">Select team member</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div id="next">
                      <button class="btn btn-next" type="submit">
                        Next
                      </button>
                      <EnterCustomerNo
                        :team_id="team_member"
                        :date="date"
                        :time="time"
                        :appointment_notes="appointment_notes"
                        :services="services"
                        :service_id="service_id"
                        v-if="makeAppointment"
                        @close="makeAppointment = false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </slot>
          </section>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
// import SingleDatePicker from "vue-single-date-picker";
import axios from "axios";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import EnterCustomerNo from "./EnterCustomerNo.vue";
import moment from "moment";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import "vue2-timepicker/dist/VueTimepicker.css";
import VueTimepicker from "vue2-timepicker";

export default {
  name: "success",
  components: {
    Navbar,
    // SingleDatePicker,
    datePicker,
    EnterCustomerNo,
    LoadingSpinner,
    VueTimepicker
  },
  props: ["services", "service_id"],
  data() {
    return {
      isLoaded: false,
      teams: [],
      team_member: "",
      team_appointments: [],
      appointment_notes: "",
      business_id: localStorage.getItem("business_id"),
      time: "",
      // timeOptions: {
      //   format: "h:mm:A",
      //   useCurrent: false
      // },
      date: new Date(),
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        keepOpen: false
      },
      makeAppointment: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getTeams() {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getteams/" + this.business_id)
        .then(response => {
          this.teams = response.data.data;
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
        });
    },
    getTeamAppointments() {
      const team_id = this.team_member;
      if (this.team_member != "default") {
        this.isLoaded = true;
        axios
          .get(
            this.$hostname +
              "/get-team-appointments?business_id=" +
              this.business_id +
              "&team_id=" +
              team_id +
              "&date=" +
              this.date
          )
          .then(response => {
            this.team_appointments = response.data.data;
            this.isLoaded = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoaded = false;
          });
      }
    },
    createAppointment() {
      this.makeAppointment = true;
    }
  },
  mounted() {
    this.getTeams();
    this.date = moment().format("YYYY-MM-DD");
  }
};
</script>

<style scoped>
@import "~vue-single-date-picker/dist/vue-single-date-picker.css";

.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
}
.items-container {
  margin: 20px 20% 0px 20%;
}
#item-menu h5 {
  color: #707070;
  font-size: 17px;
  text-decoration: underline;
  text-align: left;
}
#item-menu p {
  font-size: 12px;
  color: #be2532;
  font-weight: bold;
  text-align: left;
}
#item-menu select {
  margin-bottom: 60px;
}
#item-menu table {
  margin-bottom: 50px;
  text-align: left;
}
#item-menu table tr {
  border: none;
  height: 20px;
}
#item-menu table tr td {
  color: #707070;
  font-size: 12px;
}
#item-menu table #total td {
  color: #22a860;
  border-top: 0.5px solid silver;
  font-weight: bold;
  font-size: 12px;
}
#item-details {
  text-align: left;
}
#item-details label {
  text-align: left;
  color: #707070;
  font-size: 15px;
}
#item-details h3 {
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 28px;
}
#item-details p {
  color: #707070;
  font-size: 12px;
  width: 60%;
  margin-bottom: 30px;
  text-align: left;
}
#item-details table thead tr th {
  font-weight: bold;
  font-size: 12px;
}
#item-details table tbody tr td {
  font-size: 12px;
}
#item-details table thead #status,
#item-details table tbody #status {
  text-align: right;
}
#item-details .btn-complete {
  height: 28px;
  font-size: 12px;
  border: 1px solid #001637;
}
#next {
  margin-top: 60px;
  margin-bottom: 100px;
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
