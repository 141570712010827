<template>
  <div>
    <div class="teams">
      <div class="row page-heading">
        <div class="col-md-12 col-xs-12">
          <h4>Team Members</h4>
        </div>
      </div>

      <div class="row locations-menu">
        <div class="col-md-3 col-xs-12 offset-md-9 new-item-button">
          <button class="btn btn-save" @click="isCreateTeam = true">
            Create team member
          </button>
          <create-team v-show="isCreateTeam" @close="isCreateTeam = false" />
        </div>
      </div>

      <div v-if="isLoading">
        <LoadingSpinner />
      </div>

      <edit-team
        v-show="isEditTeam"
        @close="isEditTeam = false"
        :teamDetails="selectedTeam"
      />
      <delete-team
        v-show="isDeleteTeam"
        @close="isDeleteTeam = false"
        :teamDetails="selectedTeam"
      />

      <div class="row items-table">
        <div class="col-md-12 col-xs-12">
          <table>
            <thead>
              <tr>
                <th class="item">Name</th>
                <th>Job Title</th>
                <th>Status</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="team in teams" :key="team.id">
                <td class="item">{{ team.name }}</td>
                <td>{{ team.job_title }}</td>
                <td>
                  <div v-if="team.status">
                    Active
                  </div>
                  <div v-else>
                    Inactive
                  </div>
                </td>
                <td class="elipsis">
                  <button
                    type="button"
                    class="btn btn-danger dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img src="@/assets/elipsis.svg" width="30px" />
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" role="menu">
                    <li>
                      <a href="#" @click="editTeam(team)">Edit</a>
                    </li>
                    <li>
                      <a
                        href="#"
                        @click="deleteTeam(team)"
                        style="color: #BD2532"
                        >Delete</a
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTeam from "./Create";
import EditTeam from "./Edit";
import DeleteTeam from "./Delete";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "teams",
  components: { LoadingSpinner, CreateTeam, EditTeam, DeleteTeam },
  data() {
    return {
      business_id: localStorage.getItem("business_id"),
      isCreateTeam: false,
      isEditTeam: false,
      isDeleteTeam: false,
      isLoading: false,
      teams: [],
      selectedTeam: ""
    };
  },
  methods: {
    getTeams() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getteams/" + this.business_id)
        .then(response => {
          this.teams = response.data.data;
          this.isLoading = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoading = false;
          alert("Error fetching team members");
        });
    },
    editTeam(team) {
      this.isEditTeam = true;
      this.selectedTeam = team;
    },
    deleteTeam(team) {
      this.isDeleteTeam = true;
      this.selectedTeam = team;
    }
  },
  mounted() {
    this.getTeams();
  }
};
</script>

<style scoped>
.page-heading h4 {
  font-weight: bold;
  font-size: 30px;
}
.locations-menu {
  margin-top: 30px;
  margin-bottom: 20px;
}
.new-item-button button {
  float: right;
}
.btn-save,
.btn-save:hover {
  color: #ffffff;
  font-weight: bold;
  background-color: #bd2532;
  border-radius: 5px;
  height: 37px;
}

table .item {
  width: 40%;
  padding-left: 30px;
}

table tbody .item {
  color: #bd2532;
}

table .price {
  text-align: right;
  width: 20%;
  padding-right: 10px;
}

.elipsis {
  text-align: right;
  width: 20%;
}
.btn-danger,
.btn-danger:focus,
.btn-danger:active,
.btn-danger:hover {
  background-color: #ffffff;
  border: none;
  border-color: #ffffff;
}
.dropdown-menu {
  width: 50px !important;
  padding-left: 20px;
  font-weight: bold;
}
</style>
