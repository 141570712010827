<template>
  <div>
    <transition name="modal-fade">
      <form @submit.prevent="deleteTeam" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="heading">
                <img src="@/assets/multiply.png" @click="close" />
                <span>Disable Team Member</span>
              </div>

              <div class="delete-team">
                <h4>
                  Are you sure you would like to disable this team member ?
                </h4>
              </div>
            </slot>
          </section>

          <footer class="modal-footer">
            <slot name="footer">
              <button
                type="submit"
                class="btn-save"
                aria-label="Delete Team Member"
              >
                Disable Team Member
              </button>
              <div v-if="isLoading">
                <LoadingSpinner />
              </div>
            </slot>
          </footer>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "deleteModal",
  components: { LoadingSpinner },
  props: ["teamDetails"],
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteTeam() {
      this.isLoading = true;
      axios
        .delete(this.$hostname + "/deleteteam/" + this.teamDetails.id)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isLoading = false;
          this.$router.go();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoading = false;
          alert("An error occured while deleting Team");
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below */
  top: 200px;
  left: 30%;
  width: 45%;
  height: 250px;
  border-radius: 10px;
}

.modal-footer {
  justify-content: flex-end;
  border: none;
  display: flex;
}

.modal-body {
  position: relative;
  padding: 20px 10px 20px;
}
.heading {
  border-bottom: 1px solid silver;
  padding-bottom: 34px 24px 34px 24px;
  height: 60px;
}
.heading img {
  padding-left: 24px;
}
.heading span {
  font-weight: bold;
  font-size: 20px;
  padding-left: 30%;
}

.delete-team {
  margin-top: 40px;
  text-align: center;
}

.delete-team h4 {
  font-size: 14px;
}

.btn-save {
  color: white;
  background: #bd2532;
  border: 1px solid #bd2532;
  border-radius: 4px;
  font-weight: bold;
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
  margin-right: 5%;
}
</style>
