<template>
  <div>
    <transition name="modal-fade">
      <form
        method="post"
        @submit.prevent="updateCustomerDetails"
        @cancel="close"
      >
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <Navbar />
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="heading">
                <img
                  src="@/assets/left-arrow.png"
                  class="btn-close"
                  @click="close"
                />
              </div>

              <div id="customer-container">
                <div v-if="isLoaded">
                  <LoadingSpinner />
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <h3>Enter Customer Details</h3>
                  </div>
                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name *"
                      v-model="first_name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last name *"
                      v-model="last_name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <date-picker
                      v-model="date_of_birth"
                      placeholder="Date of birth"
                      :config="options"
                    ></date-picker>
                  </div>
                  <div class="form-group col-md-6">
                    <select class="form-control" v-model="gender" required>
                      <option value="">Select gender</option>
                      <option>Female</option>
                      <option>Male</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div class="row" id="book-appointment">
                  <div class="col-md-12">
                    <button class="btn btn-book" type="submit">
                      Edit Customer
                    </button>
                  </div>
                </div>
              </div>
            </slot>
          </section>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import axios from "axios";
// import moment from "moment";

export default {
  name: "success",
  components: {
    Navbar,
    LoadingSpinner,
    datePicker
  },
  props: ["details"],
  data() {
    return {
      completeBooking: false,
      isLoaded: false,
      first_name: this.details.first_name,
      last_name: this.details.last_name,
      gender: this.details.sex,
      customer_id: this.details.customer_id,
      date_of_birth: this.details.date_of_birth,
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        keepOpen: false
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateCustomerDetails() {
      this.isLoaded = true;
      axios
        .put(this.$hostname + "/editCustomer/" + this.customer_id, {
          first_name: this.first_name,
          last_name: this.last_name,
          sex: this.gender,
          date_of_birth: this.date_of_birth
        })
        .then(response => {
          console.log(response.data.message);
          this.isLoaded = false;
          window.location.reload();
        })
        .catch(error => {
          this.isLoaded = false;
          alert("an error occured, please try again");
          console.log(error);
        });
    }
  },
  mounted() {
    this.date_of_birth = null;
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px 40px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
  text-align: left;
}
#customer-container {
  margin: 20px 25% 0px 25%;
}
#customer-container h3 {
  color: #3a3e46;
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 33px;
  text-align: left;
}
#customer-container input,
#customer-container select {
  height: 50px;
  margin-bottom: 20px;
}
#book-appointment {
  text-align: center;
}
.btn-book {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  width: 200px;
  height: 49px;
  top: 90%;
  position: relative;
  margin-bottom: 100px;
}
</style>
