<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="top">
                <img src="@/assets/multiply.png" @click="close" />
                <h4>
                  Library
                </h4>
              </div>
              <div class="modal-body">
                <table>
                  <tbody>
                    <tr
                      v-for="team in teams"
                      :key="team.id"
                      @click="getTeam(team)"
                    >
                      <td>{{ team.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    teams: {
      type: Array
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getTeam(team) {
      this.$emit("selectedTeam", team);
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  border-radius: 0;
  height: 500px;
  width: 600px;
}
.modal-body {
  padding-left: 140px;
  padding-right: 140px;
  margin-bottom: 64px;
  overflow: scroll;
  text-align: left;
}
.top img {
  float: left;
  padding: 33px 0px 0px 34px;
}
.top h4 {
  text-align: center;
  margin-top: 54px;
  color: #37383b;
  font-size: 23px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .modal-content {
    height: 400px;
    width: 100%;
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
  }
}
</style>
