import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import numeral from "numeral";
import numberFormat from "vue-filter-number-format";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import ScrollLoader from "vue-scroll-loader";
import Multiselect from "vue-multiselect";
import vuetify from "./plugins/vuetify";

Vue.use(ScrollLoader);

Vue.component("multiselect", Multiselect);

Vue.use(BootstrapVue);

Vue.use(VueLoaders);
Vue.filter("numberFormat", numberFormat(numeral));

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

Vue.prototype.$hostname = "https://workspace.yamzit.com/api/v1";
// Vue.prototype.$hostname = "http://127.0.0.1:8001/api/v1";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
