<template>
  <div>
    <!-- show select services component -->
    <div v-if="visible">
      <h3>Select services</h3>
      <form @submit.prevent="selectOptions" autocomplete="off">
        <div v-if="item">
          <v-text-field
            @click="showCategories = true"
            v-model="item.name"
            :counter="10"
            label="Select service"
            required
          ></v-text-field>
        </div>
        <div v-else>
          <v-text-field
            @click="showCategories = true"
            v-model="item"
            :counter="10"
            label="Select service"
            required
          ></v-text-field>
        </div>
        <category
          v-show="showCategories"
          @close="showCategories = false"
          :categories="categories"
          :items="items"
          @selectedItem="showSelectedItem"
        />
        <button type="submit" class="btn btn-book">Next</button>
      </form>
    </div>
    <div v-else>
      <select-options
        v-show="selectOptionsVisible"
        @close="selectOptionsVisible = false"
        :item="item"
        :teams="teams"
        :businessDetails="businessDetails"
      />
    </div>
  </div>
</template>

<script>
import Category from "./Modals/Category";
import SelectOptions from "./SelectOptions";

export default {
  name: "Services",
  components: {
    Category,
    SelectOptions
  },
  props: {
    categories: {
      type: Array
    },
    items: {
      type: Array
    },
    teams: {
      type: Array
    },
    businessDetails: {
      type: Object
    }
  },
  data() {
    return {
      visible: true,
      item: null,
      showCategories: false,
      selectOptionsVisible: false
    };
  },
  methods: {
    showSelectedItem(event) {
      this.item = event;
    },
    selectOptions() {
      this.visible = false;
      this.selectOptionsVisible = true;
    }
  }
};
</script>

<style scoped>
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
</style>
