<template>
  <div>
    <div v-if="visible">
      <h3>Enter Phone No</h3>
      <form @submit.prevent="getCustomer">
        <div class="form-group">
          <v-text-field
            v-model="phonenumber"
            :counter="10"
            label="Phonenumber"
            required
          ></v-text-field>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-book">
            Next
          </button>
        </div>
        <div id="spinner" v-if="isLoading">
          <LoadingSpinner />
        </div>
      </form>
    </div>
    <div v-else>
      <div v-if="customer">
        <customer-details
          :item="item"
          :date="date"
          :team="team"
          :time="time"
          :customer="customer"
          :businessDetails="businessDetails"
        />
      </div>
      <div v-else>
        <new-customer
          :item="item"
          :date="date"
          :team="team"
          :time="time"
          :phonenumber="phonenumber"
          :businessDetails="businessDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";
import NewCustomer from "./NewCustomer";
import CustomerDetails from "./CustomerDetails";

export default {
  name: "PhoneNumber",
  components: {
    NewCustomer,
    CustomerDetails,
    LoadingSpinner
  },
  props: {
    item: {
      type: Object
    },
    date: {
      type: String
    },
    team: {
      type: Object
    },
    time: {
      type: String
    },
    businessDetails: {
      type: Object
    }
  },
  data() {
    return {
      visible: true,
      phonenumber: null,
      customer: null,
      isLoading: false
    };
  },
  methods: {
    getCustomer() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getcustomer/" + this.phonenumber)
        .then(response => {
          this.customer = response.data.data[0];
          this.visible = false;
          this.isLoading = false;
        })
        .catch(error => {
          error;
          alert("An error has occured retrieving customer information");
        });
    }
  }
};
</script>

<style scoped>
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
</style>
