<template>
  <div>
    <div v-if="visible">
      <h3>Details</h3>
      <form @submit.prevent="createCustomer">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <v-text-field
                v-model="firstname"
                :counter="50"
                label="Firstname"
                required
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <v-text-field
                v-model="lastname"
                :counter="50"
                label="Lastname"
                required
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <v-text-field
                v-model="email"
                :counter="50"
                label="Email"
                required
              ></v-text-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <date-picker
              v-model="date_of_birth"
              :config="options"
            ></date-picker>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <v-select
                v-model="gender"
                :items="genderItems"
                item-text="name"
                item-value="name"
                label="Gender"
                required
              ></v-select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-book">
            Next
          </button>
        </div>
        <div id="spinner" v-if="isLoading">
          <LoadingSpinner />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import datePicker from "vue-bootstrap-datetimepicker";
import axios from "axios";

export default {
  name: "PhoneNumber",
  components: {
    datePicker,
    LoadingSpinner
  },
  props: {
    item: {
      type: Object
    },
    date: {
      type: String
    },
    team: {
      type: Object
    },
    time: {
      type: String
    },
    phonenumber: {
      type: String
    },
    businessDetails: {
      type: Object
    }
  },
  data() {
    return {
      visible: true,
      date_of_birth: new Date(),
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        keepOpen: false
      },
      firstname: null,
      lastname: null,
      email: null,
      gender: null,
      genderItems: ["Male", "Female"],
      isLoading: false
    };
  },
  methods: {
    createCustomer() {
      this.isLoading = true;
      axios
        .post(this.$hostname + "/createCustomer", {
          first_name: this.firstname,
          last_name: this.lastname,
          phone_number: this.phonenumber,
          sex: this.gender,
          date_of_birth: this.date_of_birth,
          email: this.email
        })
        .then(response => {
          const customer_id = response.data.data.id;
          this.isLoading = false;
          this.makeAppointment(customer_id);
        })
        .catch();
    },
    makeAppointment(customer_id) {
      this.isLoading = true;
      axios
        .post(this.$hostname + "/createappointment", {
          business_id: this.businessDetails.business.id,
          customer_id: customer_id,
          service_id: this.item.id,
          team_id: this.team.id,
          date: this.date,
          time: this.time,
          onhold: "Onhold"
        })
        .then(response => {
          response;
          this.isLoading = false;
          this.$router.push({
            name: "CompeleteBooking",
            params: { business_name: this.businessDetails.business_url }
          });
        })
        .catch(error => {
          error;
          alert("An error occurred while making appointment");
        });
    }
  }
};
</script>

<style scoped>
.form-control {
  margin-top: 11px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 0;
}
/* .form-group {
    border: 1px solid silver;
    border-radius: 5px;
} */
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
</style>
