<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="top">
                <img src="@/assets/multiply.png" @click="close" />
                <h4>
                  Calendar
                </h4>
              </div>
              <div class="modal-body">
                <v-date-picker
                  v-model="picker"
                  @change="selectDate"
                ></v-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10)
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectDate() {
      this.$emit("selectDate", this.picker);
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/src/components/VStepper/_variables.scss";

.v-date-picker-table .v-btn.v-btn--active {
  color: #fff;
  background: #bd2532 !important;
}
.v-picker__title {
  background: #bd2532 !important;
}
</style>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  border-radius: 0;
  height: 500px;
  width: 600px;
}
.top img {
  float: left;
  padding: 33px 0px 0px 34px;
}
.top h4 {
  text-align: center;
  margin-top: 54px;
  color: #37383b;
  font-size: 23px;
  font-weight: bold;
}
.v-picker {
  margin-top: -20px;
}
.v-picker__title {
  display: none;
}
@media (max-width: 768px) {
  .modal-content {
    height: 480px;
    width: 100%;
  }
  .modal-body {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
  }
  .v-picker {
    margin-top: -30px;
  }
}
</style>
