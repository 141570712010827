<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/left-arrow.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="library-container">
              <div class="row">
                <div class="col-md-4" id="library-menu">
                  <h5>Service</h5>
                  <p>Select service…</p>

                  <h5>Select Team Member</h5>
                  <p>Select a team member…</p>

                  <h5>Select Date & Time</h5>
                  <p>Select a time & date…</p>
                </div>
                <div class="col-md-7" id="library-content">
                  <h3>Library</h3>

                  <div v-if="isLoaded">
                    <LoadingSpinner />
                  </div>

                  <table>
                    <tbody>
                      <tr
                        v-for="category in categories"
                        :key="category.id"
                        @click="getItems(category.id)"
                      >
                        <td id="icon">Tr</td>
                        <td>{{ category.name }}</td>
                        <td id="arrow">
                          <img src="@/assets/right-arrow.png" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <SelectItems
                    :category_id="selectedCategory"
                    v-if="showItems"
                    @close="showItems = false"
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-md-12">
                  <div id="next">
                    <button class="btn btn-next">Next</button>
                  </div>
                </div>
              </div> -->
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import axios from "axios";
import SelectItems from "./SelectItems.vue";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "success",
  components: {
    Navbar,
    SelectItems,
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: false,
      categories: [],
      business_id: localStorage.getItem("business_id"),
      showItems: false,
      selectedCategory: ""
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getItems(category_id) {
      // show modal
      this.selectedCategory = category_id;
      this.showItems = true;
    },
    getCategories() {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getcategories/" + this.business_id)
        .then(response => {
          this.categories = response.data.data;
          this.isLoaded = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoaded = false;
          alert("Error fetching categories");
        });
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
}
.library-container {
  margin: 20px 20% 0px 25%;
}
#library-menu h5 {
  color: #707070;
  font-size: 17px;
  text-decoration: underline;
}
#library-menu p {
  color: #d9d9d9;
  font-size: 17px;
  margin-bottom: 49px;
}
#library-content h3 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #707070;
  margin-bottom: 8px;
}
#library-content table tr {
  border-top: 0.5px solid silver;
  border-bottom: 0.5px solid silver;
}
#library-content tr #icon {
  height: 40px;
  width: 40px;
}
#library-content tr:nth-child(odd) #icon {
  background: #d0312d;
  color: #ffffff;
}
#library-content tr:nth-child(even) #icon {
  background: #991002;
  color: #ffffff;
}
#arrow {
  text-align: right;
  width: 30px;
}
#next {
  margin-top: 190px;
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
