import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_status: localStorage.getItem("login_status"),
    business_id: localStorage.getItem("business_id"),
    business_image: localStorage.getItem("business_image"),
    business_name: localStorage.getItem("business_name")
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    isLoggedIn: state => !!state.login_status
  }
});
