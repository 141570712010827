<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/left-arrow.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="items-container">
              <div class="row">
                <div class="col-md-4" id="item-menu">
                  <h5>Service</h5>
                  <table>
                    <tbody>
                      <tr v-for="service in services" :key="service.id">
                        <td>{{ service.item.name }}</td>
                        <td>UGX {{ service.item.price | numberFormat }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <h5>Select Team Member</h5>
                  <p>Select a team member…</p>
                </div>
                <div class="col-md-7" id="item-details">
                  <h3>
                    <img
                      src="@/assets/left-arrow.png"
                      class="btn-close"
                      @click="close"
                    />
                    Items
                  </h3>

                  <div v-if="isLoaded">
                    <LoadingSpinner />
                  </div>

                  <table>
                    <tbody>
                      <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                        @click="selectItem(item, index)"
                      >
                        <td id="icon">Bd</td>
                        <td id="item_name">{{ item.name }}</td>
                        <td id="cash">UGX {{ item.price | numberFormat }}</td>
                        <td id="selected">
                          <div v-if="clickedIndex === index">
                            <img src="@/assets/tick.png" />
                          </div>
                          <div v-else></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div id="next">
                    <button
                      class="btn btn-next"
                      @click="showAppointmentDetails = true"
                    >
                      Next
                    </button>
                    <AppointmentDetails
                      :services="services"
                      :service_id="service_id"
                      v-if="showAppointmentDetails"
                      @close="showAppointmentDetails = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import AppointmentDetails from "./AppointmentDetails.vue";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import axios from "axios";

export default {
  name: "success",
  props: ["category_id"],
  components: {
    Navbar,
    AppointmentDetails,
    LoadingSpinner
  },
  data() {
    return {
      isLoaded: false,
      services: [],
      service_id: "", // storing one service id
      clickedIndex: "",
      showAppointmentDetails: false,
      items: []
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getCategoryItems(category_id) {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getcategoryitems/" + category_id)
        .then(response => {
          this.items = response.data.data;
          this.isLoaded = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoaded = false;
          alert("Alert fetching category items");
        });
    },
    selectItem(item, index) {
      //clear values and add new service.. one service selected at a time
      this.services = [];
      this.service_id = "";
      this.clickedIndex = "";
      this.services.push({ item: item });
      this.clickedIndex = index;
      this.service_id = item.id;
    }
  },
  mounted() {
    this.getCategoryItems(this.category_id);
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-bottom: 20px;
}
.items-container {
  margin: 20px 20% 0px 25%;
}
#item-menu h5 {
  color: #707070;
  font-size: 17px;
  text-decoration: underline;
}
#item-menu p {
  color: #d9d9d9;
  font-size: 17px;
}
#item-menu table {
  margin-bottom: 50px;
}
#item-menu table tr {
  border: none;
  height: 20px;
}
#item-menu table tr td {
  color: #707070;
  font-size: 12px;
}
#item-details h3 {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
#item-details h3 img {
  float: left;
  height: 14px;
  width: 17px;
}
#item-details table tr #icon {
  height: 40px;
  width: 40px;
  background: #9da2a6;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
#item-details table tr #item_name {
  color: #707070;
  font-size: 12px;
  font-weight: bold;
}
#item-details table tr #cash {
  font-size: 12px;
  text-align: right;
  color: #707070;
}
#item-details table tr #selected {
  width: 17px;
}
#item-details table tr #selected img {
  height: 12px;
}
#next {
  margin-top: 190px;
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
