<template>
  <div>
    <div v-if="visible">
      <h3>Date, Team & time</h3>
      <form @submit.prevent="proceed" autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <v-text-field
              @click="isCalendarVisible = true"
              v-model="date"
              :counter="10"
              label="Select date"
              required
            ></v-text-field>
            <Calendar
              v-show="isCalendarVisible"
              @close="isCalendarVisible = false"
              @selectDate="getDate"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="team">
              <v-text-field
                @click="isTeamVisible = true"
                v-model="team.name"
                :counter="10"
                label="Select team"
                required
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                @click="isTeamVisible = true"
                v-model="team"
                :counter="10"
                label="Select team"
                required
              ></v-text-field>
            </div>
            <Team
              v-show="isTeamVisible"
              @close="isTeamVisible = false"
              :teams="teams"
              @selectedTeam="getSelectedTeam"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <v-text-field
              @click="isTimeVisible = true"
              v-model="time"
              :counter="10"
              label="Select time"
              required
            ></v-text-field>
            <Time
              v-show="isTimeVisible"
              @close="isTimeVisible = false"
              @selectTime="getTime"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button type="submit" class="btn btn-book">
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-else>
      <enter-phone-number
        :item="item"
        :date="date"
        :team="team"
        :time="time"
        :businessDetails="businessDetails"
      />
    </div>
  </div>
</template>

<script>
import Calendar from "./Modals/Calendar";
import Team from "./Modals/Team";
import Time from "./Modals/Time";
import EnterPhoneNumber from "./EnterPhoneNumber";

export default {
  name: "options",
  components: {
    Calendar,
    Team,
    Time,
    EnterPhoneNumber
  },
  props: {
    item: {
      type: Object
    },
    teams: {
      type: Array
    },
    businessDetails: {
      type: Object
    }
  },
  data() {
    return {
      visible: true,
      date: null,
      team: null,
      time: null,
      isCalendarVisible: false,
      isTeamVisible: false,
      isTimeVisible: false
    };
  },
  methods: {
    getDate(event) {
      this.date = event;
      this.isCalendarVisible = false;
    },
    getTime(event) {
      this.time = event;
      this.isTimeVisible = false;
    },
    getSelectedTeam(event) {
      this.team = event;
      this.isTeamVisible = false;
    },
    proceed() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
</style>
