<template>
  <div>
    <div class="row" id="index">
      <div class="col-md-6 image">
        <div v-if="showLogo">
          <logo-pane :businessDetails="businessDetails" />
        </div>
      </div>
      <div class="col-md-6" id="book-activity">
        <div v-if="visible">
          <h3>Book An Appointment</h3>
          <button class="btn btn-book" @click="SelectServices">Book now</button>
        </div>
        <div v-else>
          <select-services
            :categories="categories"
            :items="items"
            :teams="teams"
            :businessDetails="businessDetails"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoPane from "@/components/CustomerBooking/Layouts/LogoPane";
import SelectServices from "./SelectServices";

export default {
  name: "Index",
  props: {
    businessExist: {
      type: Boolean
    },
    categories: {
      type: Array
    },
    items: {
      type: Array
    },
    teams: {
      type: Array
    },
    businessDetails: {
      type: Object
    }
  },
  components: {
    LogoPane,
    SelectServices
  },
  data() {
    return {
      visible: true,
      showLogo: true
    };
  },
  methods: {
    SelectServices() {
      if (this.businessExist) {
        this.visible = false;
        this.isMobile();
      } else {
        alert("No business Information Found");
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.showLogo = false;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
#book-activity {
  padding-top: 50px;
}
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  #book-activity {
    padding-top: 30px;
  }
}
</style>
