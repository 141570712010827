<template>
  <div>
    <transition name="modal-fade">
      <form method="post" @submit.prevent="createItem" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <section class="modal-header">
            <img src="@/assets/multiply.png" @click="close" />

            <span>Create an Item</span>

            <button type="submit" class="btn-save">
              Save
            </button>
          </section>
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="row">
                <div class="col-md-6 offset-md-3">
                  <h4 class="sub-heading">Details</h4>

                  <div class="new-item">
                    <div class="r">
                      <label>Item Name</label>
                      <input v-model="itemName" placeholder="Name" required />
                    </div>
                    <div class="l">
                      <label>Category</label>
                      <select
                        v-model="category_id"
                        class="form-control"
                        required
                      >
                        <option value="">Select Category</option>
                        <option
                          v-for="category in categories"
                          :value="category.id"
                          :key="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                    <div class="d">
                      <label>Description</label>
                      <textarea
                        v-model="itemDescription"
                        placeholder="Optional to add"
                      ></textarea>
                    </div>

                    <div class="v">
                      <label>Locations</label>
                      <multiselect
                        class="multiselect"
                        v-model="selectedLocations"
                        :options="locations"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Select Locations"
                        label="name"
                        track-by="name"
                        :preselect-first="false"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, search, isOpen }"
                        >
                          <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                          >
                            {{ values.length }} Locations
                          </span>
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="price">
                    <h4 class="sub-heading">Price of the item</h4>
                    <div class="points">
                      <div v-for="v in selectedLocations" :key="v.id">
                        <div class="locationPrices">
                          <label>{{ v.name }}</label
                          ><input
                            v-model="v.prices"
                            placeholder="Enter Price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isLoading">
                <LoadingSpinner />
              </div>
            </slot>
          </section>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import qs from "querystring";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "newItem",
  props: ["categories", "locations"],
  components: {
    Multiselect,
    LoadingSpinner
  },
  data: function() {
    return {
      category_id: "",
      selectedLocations: [],
      itemName: "",
      itemDescription: "",
      business_id: localStorage.getItem("business_id"),
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createItem() {
      this.isLoading = true;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
      };
      this.selectedLocations.forEach(element => {
        axios
          .post(
            this.$hostname + "/createitems",
            qs.stringify({
              name: this.itemName,
              description: this.itemDescription,
              category_id: this.category_id,
              business_id: this.business_id,
              location_id: element.id,
              price: element.prices
            }),
            {
              headers: headers
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.isLoading = false;
            this.$router.go();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.isLoading = false;
            alert("Error creating new item");
          });
      });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-header {
  box-shadow: 0px 0px 6px #0000004d;
  padding: 20px;
}
.modal-header span {
  font-weight: bold;
  font-size: 20px;
}

.sub-heading {
  padding-left: 30px;
  margin-top: 50px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 19px;
}

.new-item {
  white-space: nowrap;
  padding-left: 30px;
}

.r,
.d,
.v,
.l {
  margin-top: -10px;
}

label {
  background: #ebebeb;
  color: #3a3e46;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  height: 40px;
  width: 150px;
  vertical-align: top;
  display: inline-block;
  padding-top: 10px;
  padding-left: 20px;
  margin-right: -1px;
  font-weight: bold;
  font-size: 13px;
}
.r input {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-left: none;
  height: 40px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  padding-left: 20px;
}
.d label,
.d textarea {
  height: 100px;
}
.d textarea {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-left: none;
  height: 100px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  padding-left: 20px;
}
.l select {
  height: 40px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  border-right: 1px solid silver;
  border-radius: 0px;
  border-bottom: none;
  border-left: none;
}

.v .multiselect {
  height: 40px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  border: none;
  border-radius: 0px;
  border-right: 1px solid silver;
  border-bottom: 1px solid silver;
}
.v .multiselect .multiselect__spinner {
  height: 40px;
}
.v .multiselect .multiselect__spinner input {
  height: 40px;
  border: 1px solid silver;
}
.multiselect__content-wrapper {
  border: 1px solid silver;
}
.multiselect__content li {
  list-style: none;
  padding: 0;
}
.points {
  white-space: nowrap;
  padding-left: 30px;
}
.points .locationPrices {
  margin-top: -10px;
}
.points input {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-left: none;
  height: 40px;
  width: 350px;
  vertical-align: top;
  display: inline-block;
  padding-left: 20px;
}

.btn-save {
  color: white;
  background: #bd2532;
  border: 1px solid #bd2532;
  border-radius: 5px;
  font-weight: bold;
  width: 93px;
  height: 40px;
  float: right;
}
</style>
