<template>
  <div class="container">
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="row" id="header">
              <div class="col-12 col-md-8 offset-md-2">
                <img src="@/assets/check.png" />

                <h3>Congratulations! You’re all set.</h3>

                <hr />

                <button class="btn btn-done" @click="close">
                  Continue
                </button>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Complete",
  data() {
    return {};
  },
  methods: {
    close() {
      window.location.href = "/";
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
#header {
  margin-top: 194px;
  text-align: center;
  color: #3d454c;
}
#header img {
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
}
#header h3 {
  font-size: 29px;
  margin-bottom: 100px;
}
.btn-done {
  width: 50%;
  height: 41px;
  background: #be2532;
  color: #ffffff;
  border-radius: 6px;
  margin-top: 25px;
}
@media (max-width: 768px) {
  #header {
    margin-top: 80px;
  }
  #header h3 {
    font-size: 24px;
  }
}
</style>
