<template>
  <div>
    <transition name="modal-fade">
      <form @submit.prevent="renameCategory" @cancel="close">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <div class="heading">
                <img src="@/assets/multiply.png" @click="close" />
                <span>Rename Category </span>
              </div>

              <div class="rename-category">
                <label>Category Name</label>
                <input
                  type="text"
                  name="name"
                  v-model="categoryDetails.name"
                  placeholder="Enter New Category Name"
                  required
                />
              </div>

              <div v-if="isLoading">
                <LoadingSpinner />
              </div>
            </slot>
          </section>

          <footer class="modal-footer">
            <slot name="footer">
              <button
                type="submit"
                class="btn-save"
                aria-label="Rename Category"
              >
                Save
              </button>
            </slot>
          </footer>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "renameCategoryModal",
  components: { LoadingSpinner },
  data() {
    return {
      isLoading: false
    };
  },
  props: ["categoryDetails"],
  methods: {
    close() {
      this.$emit("close");
    },
    renameCategory() {
      this.isLoading = true;
      axios
        .put(this.$hostname + "/editcategory/" + this.categoryDetails.id, {
          name: this.categoryDetails.name
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.isLoading = false;
          this.$router.go();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.isLoading = false;
          alert("An error occured while editing a category");
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below */
  top: 200px;
  left: 30%;
  width: 45%;
  height: 300px;
  border-radius: 10px;
}

.modal-footer {
  justify-content: flex-end;
  border: none;
  display: flex;
}

.modal-body {
  position: relative;
  padding: 20px 10px 20px;
}
.heading {
  border-bottom: 1px solid silver;
  padding-bottom: 34px 24px 34px 24px;
  height: 60px;
}
.heading img {
  padding-left: 24px;
}
.heading span {
  font-weight: bold;
  font-size: 20px;
  padding-left: 30%;
}

.rename-category {
  white-space: nowrap;
  margin-top: 30px;
  padding-left: 30px;
}

.rename-category label {
  background: #ebebeb;
  color: #3a3e46;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  height: 50px;
  width: 30%;
  vertical-align: top;
  display: inline-block;
  padding-top: 15px;
  padding-left: 20px;
  margin-right: -1px;
  font-weight: bold;
  font-size: 13px;
}
.rename-category input {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-left: none;
  height: 50px;
  width: 65%;
  vertical-align: top;
  display: inline-block;
  padding-left: 20px;
}

.btn-save {
  color: white;
  background: #bd2532;
  border: 1px solid #bd2532;
  border-radius: 4px;
  font-weight: bold;
  width: 110px;
  height: 40px;
  margin-bottom: 20px;
  margin-right: 5%;
}
</style>
