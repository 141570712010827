<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/left-arrow.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="row" id="details">
              <div class="col-md-12">
                <h3>Customer Details</h3>

                <div v-if="isLoaded">
                  <LoadingSpinner />
                </div>

                <div class="form-group">
                  <label>Phone No *</label>
                  <br />
                  <input
                    type="text"
                    v-model="phone_number"
                    required
                    placeholder="0775995738"
                  />
                  <p>Customer phone number</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" id="next-button">
                  <button
                    class="btn btn-next"
                    type="submit"
                    @click="getCustomerDetails()"
                  >
                    Next
                  </button>
                  <!-- if number exists, got to customer details -->
                  <CustomerDetails
                    :team_id="team_id"
                    :date="date"
                    :time="time"
                    :appointment_notes="appointment_notes"
                    :service_id="service_id"
                    :customer_details="customer_details"
                    v-if="customerdetailsmodal"
                    @close="customerdetailsmodal = false"
                  />
                  <!-- if number doesnt exist, got to new customer details -->
                  <NewCustomerDetails
                    :team_id="team_id"
                    :date="date"
                    :time="time"
                    :appointment_notes="appointment_notes"
                    :service_id="service_id"
                    :phone_number="phone_number"
                    v-if="newcustomermodal"
                    @close="newcustomermodal = false"
                  />
                </div>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import axios from "axios";
import CustomerDetails from "./CustomerDetails.vue";
import NewCustomerDetails from "./NewCustomerDetails.vue";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "success",
  components: {
    Navbar,
    CustomerDetails,
    NewCustomerDetails,
    LoadingSpinner
  },
  props: [
    "team_id",
    "date",
    "time",
    "appointment_notes",
    "services",
    "service_id"
  ],
  data() {
    return {
      isLoaded: false,
      phone_number: "",
      customer_details: [],
      customerdetailsmodal: false,
      newcustomermodal: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getCustomerDetails() {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getcustomer/" + this.phone_number)
        .then(response => {
          if (response.data.data[0]) {
            this.customer_details = response.data.data[0];
            this.customerdetailsmodal = true;
          } else {
            this.newcustomermodal = true;
          }
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
        });
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}
.modal-body {
  position: relative;
  padding: 20px 10px;
}
.heading {
  padding-bottom: 20px;
  text-align: left;
}
#details {
  margin: 20px 35% 0px 35%;
  text-align: left;
}
#details h3 {
  color: #3a3e46;
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 44px;
  text-align: left;
}
#details label {
  color: #697478;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 14px;
  text-align: left;
}
#details input {
  width: 100%;
  height: 49px;
  border-radius: 6px;
  border: 0.5px solid #707070;
  margin-bottom: 11px;
  padding-left: 20px;
}
#details input:placeholder-shown {
  padding-left: 20px;
}
#details p {
  color: #697478;
  font-weight: 10px;
  text-align: left;
}
#next-button {
  text-align: center;
}
.btn-next {
  text-align: center;
  background: #be2532;
  color: #ffffff;
  width: 200px;
  height: 49px;
  font-size: 16px;
  margin-top: 200px;
  margin-bottom: 100px;
}
</style>
