<template>
  <div class="container">
    <div class="row" id="header">
      <div class="col-md-12 col-12">
        <h3>Let’s create your account.</h3>
        <p>
          Signing up for Yamzit - iKO is fast and free — no commitments or
          long-term contracts.
        </p>
      </div>
    </div>

    <form @submit.prevent="proceed()">
      <div class="row" id="content">
        <div class="col-12 col-md-6 form-group">
          <label>Enter your email</label>
          <input
            type="email"
            class="form-control"
            v-model="user_data.email"
            placeholder="you@example.com"
            required
          />
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>Your Phone number</label>
          <input
            type="text"
            pattern="[0-9]*"
            minlength="10"
            maxlength="10"
            title="Enter valid phone number"
            class="form-control"
            v-model="user_data.phone_number"
            placeholder="07xxxxxxxx"
            required
          />
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>Create a pin</label>
          <input
            type="password"
            pattern="[0-9]*"
            minlength="5"
            maxlength="5"
            title="Enter a pin of 5 characters"
            inputmode="numeric"
            class="form-control"
            v-model="user_data.password"
            placeholder="*****"
            required
          />
        </div>

        <div class="col-12 col-md-6 form-group">
          <label>Country</label>
          <select class="form-control" v-model="user_data.country" required>
            <option value="">Select Country</option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>

      <div class="row" id="continue">
        <div class="col-12 col-md-6">
          <p>
            By continuing, you agree to the Seller Agreement and Privacy Policy.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <button type="submit" class="btn btn-continue">
            continue
          </button>
        </div>
      </div>

      <BusinessDetails
        v-if="showBusinessDetails"
        @close="showBusinessDetails = false"
        :business_types="business_types"
        :user_data="user_data"
      />

      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>
    </form>

    <div class="row" id="signin">
      <div class="col-12 col-md-12">
        <p>
          Already have a Yamzit account?
          <a href="/">
            Log In
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessDetails from "@/components/Auth/registerComponents/BusinessDetails";

export default {
  name: "UserDetails",
  components: {
    BusinessDetails
  },
  props: ["countries", "business_types"],
  data() {
    return {
      user_data: {
        email: "",
        phone_number: "",
        password: "",
        country: 1
      },
      showBusinessDetails: false
    };
  },
  methods: {
    proceed() {
      this.showBusinessDetails = true;
    }
  }
};
</script>

<style scoped>
.container {
  padding: 100px;
  max-width: 100%;
}
#header {
  margin-bottom: 120px;
  text-align: center;
}
#header h3 {
  color: #3d454c;
  font-size: 26px;
  margin-bottom: 11px;
}
#header p {
  color: #3d454c;
  font-size: 16px;
}
#content label {
  font-size: 12px;
  font-weight: bold;
}
#content input,
#content select {
  border: 1px solid #707070;
}
#continue {
  margin-top: 25px;
  margin-bottom: 25px;
}
#continue p {
  color: #3d454c;
  font-size: 12px;
  padding-top: 10px;
}
#continue .btn-continue {
  width: 100%;
  height: 41px;
  background: #be2532;
  color: #ffffff;
  border-radius: 6px;
}
#signin {
  text-align: center;
  margin-top: 25px;
}
#signin p {
  font-size: 16px;
}
#signin p a {
  color: #be2532;
  font-weight: bold;
}
@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
  #header {
    margin-bottom: 40px;
  }
}
</style>
