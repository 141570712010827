<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/multiply.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="row" id="library-content">
              <div class="col-md-12">
                <h3>Library</h3>
                <table>
                  <tbody>
                    <tr
                      v-for="category in categories"
                      :key="category.id"
                      @click="getItems(category.id)"
                    >
                      <td id="icon">Tr</td>
                      <td>{{ category.name }}</td>
                      <td id="arrow">
                        <img src="@/assets/right-arrow.png" />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="isLoaded">
                  <LoadingSpinner />
                </div>

                <ItemsModal
                  :details="details"
                  :items="categoryItems"
                  :current_item_id="details.item_id"
                  v-if="itemsModal"
                  @close="itemsModal = false"
                />
              </div>
            </div>

            <div class="row" id="next">
              <div class="col-md-12">
                <button class="btn btn-next" @click="close">
                  Save Changes
                </button>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import axios from "axios";
import ItemsModal from "./Items.vue";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "success",
  components: {
    Navbar,
    ItemsModal,
    LoadingSpinner
  },
  props: ["details"],
  data() {
    return {
      isLoaded: false,
      categories: [],
      itemsModal: false,
      categoryItems: [],
      business_id: localStorage.getItem("business_id")
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getCategories() {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getcategories/" + this.business_id)
        .then(response => {
          console.log(response.data.data);
          this.categories = response.data.data;
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
        });
    },
    getItems(category_id) {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/getcategoryitems/" + category_id)
        .then(response => {
          console.log(response.data.data);
          this.categoryItems = response.data.data;
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
        });
      // show modal
      this.itemsModal = true;
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-left: 20px;
  padding-bottom: 20px;
}
#library-content {
  margin: 0px 28% 0px 28%;
}
#library-content h3 {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #707070;
  margin-bottom: 8px;
}
#library-content table tr {
  border-top: 0.5px solid silver;
  border-bottom: 0.5px solid silver;
}
#library-content tr #icon {
  height: 40px;
  width: 40px;
}
#library-content tr:nth-child(odd) #icon {
  background: #d0312d;
  color: #ffffff;
}
#library-content tr:nth-child(even) #icon {
  background: #991002;
  color: #ffffff;
}
#arrow {
  text-align: right;
  width: 30px;
}
#next {
  position: relative;
  top: 60%;
  /* margin-top: 190px; */
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
