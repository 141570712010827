<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="row">
              <div class="col-md-6">
                <h3>Booking Saved!</h3>
                <p>This appointment has been saved</p>
              </div>
              <div class="col-md-6">
                <img src="@/assets/Schedule-bro.png" />
              </div>
            </div>

            <div class="row return">
              <div class="col-md-12">
                <a href="/upcoming" @click="close">Return to home page</a>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";

export default {
  name: "success",
  components: {
    Navbar
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}
h3 {
  margin-top: 30%;
  color: #be2532;
  font-size: 46px;
  font-weight: bold;
  text-align: center;
}
p {
  color: #697478;
  font-size: 17px;
  text-align: center;
}
img {
  width: 60%;
  height: auto;
}
.return {
  text-align: center;
  margin-top: 70px;
}
a {
  color: #be2532;
  text-align: center;
  text-decoration: underline;
  font-size: 17px;
}
</style>
