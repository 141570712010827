<template>
  <div>
    <div class="row" v-for="(route, index) in routes" :key="index">
      <div class="col-md-12">
        <router-link :to="{ path: route.link }">
          <div class="link-item">
            {{ route.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  props: ["routes"]
};
</script>

<style scoped>
.row {
  height: 50px;
}
.link-item {
  min-height: 42px;
  padding: 13px 0px 12px 12px;
  color: #3a3e46;
  font-weight: bold;
  font-size: 14px;
  width: 90%;
}
a,
a:hover {
  text-decoration: none;
}
.router-link-exact-active .link-item {
  background-color: #fff2f3;
  color: #bd2532;
}
</style>
