<template>
  <div class="upcoming">
    <div class="row" id="header">
      <div class="col-md-12">
        <h2>Appointment Requests</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <date-range-picker
          ref="picker"
          :opens="opens"
          :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
          :minDate="minDate"
          :maxDate="maxDate"
          :autoApply="autoApply"
          v-model="dateRange"
          @update="getAppointments"
        >
          <template v-slot:input="picker">
            {{ moment(picker.startDate).format("MMM DD, YYYY") }} -
            {{ moment(picker.endDate).format("MMM DD, YYYY") }}
          </template>
        </date-range-picker>
      </div>
      <div class="col-md-3 form-group">
        <select v-model="team" class="form-control" @change="getAppointments">
          <option value="all">Select Team Member</option>
          <option v-for="team in teams" :key="team.id" :value="team.id">
            {{ team.name }}
          </option>
        </select>
      </div>
      <div class="col-md-3 offset-md-2">
        <button class="btn btn-new" @click="showModal = true">
          New appointment
        </button>
        <addAppointment v-if="showModal" @close="showModal = false" />
      </div>
    </div>

    <div v-if="isLoaded">
      <LoadingSpinner />
    </div>

    <!-- modal to view clicked appointment -->
    <EditAppointment
      :details="selectedDetails"
      v-if="showAppointmentDetails"
      @close="showAppointmentDetails = false"
    />

    <div class="row" id="data">
      <div class="col-md-12">
        <div v-if="appointmentrequests.length > 0">
          <table>
            <tbody v-for="(up, index) in appointmentrequests" :key="up.id">
              <tr id="date_row">
                <td style="width: 250px">
                  {{ moment(up.date).format("dddd, MMMM DD, YYYY") }}
                </td>
                <td style="width: 150px">
                  <div v-if="index == 0">Name</div>
                </td>
                <td><div v-if="index == 0">Phone Number</div></td>
                <td><div v-if="index == 0">Service</div></td>
                <td><div v-if="index == 0">Employee</div></td>
                <td><div v-if="index == 0">Notes</div></td>
                <td><div v-if="index == 0">Approve</div></td>
              </tr>
              <tr
                v-for="appointment in up.appointments"
                :key="appointment.id"
                @click="viewAppointment(appointment)"
              >
                <td>{{ appointment.time }}</td>
                <td>
                  {{ appointment.first_name + " " + appointment.last_name }}
                </td>
                <td>{{ appointment.phone_number }}</td>
                <td>{{ appointment.item_name }}</td>
                <td>{{ appointment.team_name }}</td>
                <td>{{ appointment.appointment_notes }}</td>
                <td>
                  <div v-if="appointment.appointment_status == 'Onhold'">
                    <button
                      class="btn btn-complete"
                      @click="approveAppointment(appointment.id)"
                    >
                      Approve
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table>
            <tr id="date_row">
              <td style="width: 300px"></td>
              <td>Name</td>
              <td>Phone Number</td>
              <td>Service</td>
              <td>Employee</td>
              <td>Notes</td>
              <td>Complete</td>
            </tr>
            <tr>
              <td colspan="7">No results</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import addAppointment from "@/components/Appointments/AddAppointment/SelectCategory.vue";
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import EditAppointment from "./EditAppointment/EditAppointment.vue";

export default {
  name: "AppointmentRequests",
  components: {
    DateRangePicker,
    addAppointment,
    LoadingSpinner,
    EditAppointment
  },
  data() {
    return {
      appointmentrequests: [],
      showModal: false,
      isLoaded: false,
      //date range picker variables
      opens: "center",
      minDate: null,
      maxDate: null,
      singleDatePicker: false,
      showDropdowns: true,
      autoApply: true,
      dateRange: {
        startDate: moment(),
        endDate: moment().add(30, "days")
      },
      //end of daterange picker variables
      business_id: localStorage.getItem("business_id"),
      showAppointmentDetails: false,
      selectedDetails: [],
      teams: [],
      team: "all"
    };
  },
  methods: {
    getTeams() {
      axios
        .get(this.$hostname + "/getteams/" + this.business_id)
        .then(response => {
          this.teams = response.data.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          alert("Error fetching team members");
        });
    },
    getAppointments() {
      this.isLoaded = true;
      this.appointmentrequests = [];
      axios
        .get(
          this.$hostname +
            "/get-appointments?business_id=" +
            this.business_id +
            "&start_date=" +
            moment(this.dateRange.startDate).format("YYYY-MM-DD") +
            "&end_date=" +
            moment(this.dateRange.endDate).format("YYYY-MM-DD") +
            "&team=" +
            this.team +
            "&onhold='Onhold'"
        )
        .then(response => {
          this.appointmentrequests = response.data.data;
          this.isLoaded = false;
        })
        .catch(error => {
          error;
          this.isLoaded = false;
          alert("An error occured");
        });
    },
    approveAppointment(appointment_id) {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/approve/" + appointment_id)
        .then(response => {
          response;
          window.location.reload();
          this.isLoaded = false;
        })
        .catch(error => {
          error;
          this.isLoaded = false;
        });
    },
    viewAppointment(appointment) {
      this.showAppointmentDetails = true;
      this.selectedDetails = appointment;
    }
  },
  created() {
    this.getAppointments();
    this.getTeams();
  }
};
</script>

<style scoped>
#header {
  margin-bottom: 60px;
}
h2 {
  font-weight: bold;
  font-size: 24px;
}
.btn-new {
  background-color: #bd2532;
  color: #ffffff;
  border-radius: 5px;
  float: right;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
}
.btn-complete {
  background-color: #ffffff;
  color: #001637;
  font-weight: bold;
  border: 1px solid #001637;
  font-size: 9px;
  border-radius: 10px;
  padding: -5px !important;
}
#data {
  margin-top: 20px;
}
table tbody tr td {
  font-size: 13px;
  height: 40px;
}
#date_row {
  background-color: #ebebeb;
  color: #001637;
  border-bottom: none;
  font-weight: bold;
  height: 40px;
}
.cancelled td {
  color: #be2532;
}
.vue-daterange-picker {
  width: 100%;
  height: auto;
}
</style>
