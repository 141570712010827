<template>
  <div>
    <transition name="modal-fade">
      <!-- <form method="post" @submit.prevent="createItem" @cancel="close"> -->
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/multiply.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="edit-container">
              <div class="row locked">
                <div class="col-md-6">
                  <h5>Customer details</h5>
                </div>
                <div class="col-md-6">
                  <!-- <img src="@/assets/lock.png" /> -->
                  <h4 @click="editcategorymodal = true">Edit</h4>
                  <EditCustomer
                    :details="details"
                    v-if="editcategorymodal"
                    @close="editcategorymodal = false"
                  />
                </div>
              </div>

              <div class="row customer-details">
                <div class="col-md-6">
                  <label>Names * </label>
                  <br />
                  <p>{{ details.first_name + " " + details.last_name }}</p>

                  <label>Date Of Birth *</label>
                  <br />
                  <p>
                    {{ moment(details.date_of_birth).format("DD / MM / YYYY") }}
                  </p>
                </div>
                <div class="col-md-6">
                  <label>Gender *</label>
                  <br />
                  <p>{{ details.sex }}</p>

                  <label>Email *</label>
                  <br />
                  <p>{{ details.email }}</p>
                </div>
              </div>
              <hr />

              <div class="row service">
                <div class="col-md-6">
                  <h5>Service</h5>
                </div>
                <div class="col-md-6">
                  <h4 @click="categorymodal = true">Edit</h4>
                  <CategoryModal
                    :details="details"
                    v-if="categorymodal"
                    @close="categorymodal = false"
                  />
                </div>
                <div class="col-md-12">
                  <table>
                    <tbody>
                      <tr>
                        <td>{{ details.item_name }}</td>
                        <td>UGX {{ details.item_price | numberFormat }}</td>
                        <td><img src="@/assets/error.png" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-12">
                  <label style="color: #697478; font-weight: bold">Notes</label>
                </div>
                <div class="col-md-12">
                  <p style="color: #697478">{{ details.appointment_notes }}</p>
                </div>
              </div>
              <hr />

              <div class="row booking">
                <div class="col-md-6">
                  <h5>Booking details</h5>
                </div>
                <div class="col-md-6">
                  <h4 @click="appointmentdetailsmodal = true">Edit</h4>
                  <AppointmentDetail
                    :details="details"
                    v-if="appointmentdetailsmodal"
                    @close="appointmentdetailsmodal = false"
                  />
                </div>
                <div class="col-md-12">
                  <table>
                    <tbody>
                      <tr>
                        <td>Employee</td>
                        <td>{{ details.team_name }}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          {{ moment(details.date).format("DD / MM / YYYY") }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ details.time }}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row buttons">
                <div class="col-md-6">
                  <div
                    v-if="
                      details.appointment_status === 'Pending' ||
                        details.appointment_status === 'Onhold'
                    "
                  >
                    <button
                      class="btn btn-cancel"
                      @click="cancelAppointment(details.id)"
                    >
                      Cancel booking
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <button class="btn btn-save">Save Changes</button>
                </div>
              </div>

              <div v-if="isLoaded">
                <LoadingSpinner />
              </div>
            </div>
          </slot>
        </section>
      </div>
      <!-- </form> -->
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import CategoryModal from "./Category.vue";
import AppointmentDetail from "./AppointmentDetails.vue";
import EditCustomer from "./EditCustomer.vue";

export default {
  name: "success",
  components: {
    Navbar,
    LoadingSpinner,
    CategoryModal,
    AppointmentDetail,
    EditCustomer
  },
  props: ["details"],
  data() {
    return {
      isLoaded: false,
      categorymodal: false,
      appointmentdetailsmodal: false,
      editcategorymodal: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    cancelAppointment(appointment_id) {
      this.isLoaded = true;
      axios
        .get(this.$hostname + "/cancel-appointment/" + appointment_id)
        .then(response => {
          console.log(response);
          window.location.reload();
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}

.modal-body {
  overflow: auto;
  position: relative;
  padding: 20px 10px;
}
.heading {
  padding-left: 20px;
  padding-bottom: 20px;
}
.edit-container {
  margin: 0px 27% 0px 27%;
}
.locked {
  margin-bottom: 27px;
}
.locked h5 {
  text-decoration: underline;
  color: #707070;
  font-size: 17px;
  font-weight: bold;
}
.locked img {
  height: 18px;
  float: right;
}
.customer-details label {
  color: #697478;
  font-weight: 12px;
  opacity: 1;
}
.customer-details p {
  color: #697478;
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
}
.service {
  margin-top: 20px;
}
.service h5 {
  text-decoration: underline;
  font-size: 17px;
  color: #707070;
  font-weight: bold;
}
.service h4,
.locked h4 {
  text-align: right;
  color: #be2532;
  font-size: 16px;
}
.booking {
  margin-top: 20px;
}
.booking h5 {
  text-decoration: underline;
  font-size: 17px;
  color: #707070;
  font-weight: bold;
}
.booking h4 {
  text-align: right;
  color: #be2532;
  font-size: 16px;
}
table tbody tr {
  border: none;
}
table tbody tr td:nth-child(1) {
  padding: 0;
  font-weight: bold;
  color: #697478;
  font-size: 16px;
}
table tbody tr td:nth-child(2) {
  text-align: right;
  color: #697478;
  font-size: 16px;
}
table tbody tr td:nth-child(3) {
  width: 100px;
  text-align: right;
}
.buttons {
  margin-top: 100px;
  margin-bottom: 100px;
}
.btn-cancel {
  border: 1px solid #3a3e46;
  height: 49px;
  width: 200px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 100px;
}
.btn-save {
  border: 1px solid #be2532;
  background: #be2532;
  height: 49px;
  width: 200px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  float: right;
  margin-bottom: 100px;
}
</style>
