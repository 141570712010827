<template>
  <div>
    <div class="row" id="book-header">
      <div class="col-md-12">
        <a href="">
          <img src="@/assets/left-arrow.png" @click="reloadPage" />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-4">
        <div id="spinner" v-if="isLoading">
          <LoadingSpinner />
        </div>
      </div>
    </div>
    <Index
      :businessExist="businessExist"
      :categories="categories"
      :items="items"
      :teams="teams"
      :businessDetails="businessDetails"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";
import Index from "@/components/CustomerBooking/Index";
import axios from "axios";

export default {
  name: "Booking",
  components: {
    Index,
    LoadingSpinner
  },
  data() {
    return {
      business_name: this.$route.params.business_name,
      business_id: null,
      business: null,
      businessDetails: {
        business_name: null,
        business: null,
        business_logo: null,
        business_url: null
      },
      categories: [],
      items: [],
      teams: [],
      isLoading: false,
      businessExist: false
    };
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    getBusinessDetails() {
      this.isLoading = true;
      const business_name = this.$route.params.business_name;
      axios
        .get(this.$hostname + "/getBusinessByName?name=" + business_name)
        .then(response => {
          this.isLoading = false;
          const details = response.data.data;
          if (details) {
            this.businessExist = true;
            this.business = details;
            this.business_id = details.id;
            this.businessDetails.business_name = details.name;
            this.businessDetails.business = details;
            this.businessDetails.business_logo = details.image;
            this.businessDetails.business_url = business_name;
            this.getBusinessRequirements();
          } else {
            this.businessExist = false;
            alert("No business information found");
          }
        })
        .catch(error => {
          error;
          this.isLoading = false;
          alert("An Error Occured getting business details");
        });
    },
    getBusinessRequirements() {
      if (this.business_id) {
        this.getCategories();
        this.getItems();
        this.getTeams();
      }
    },
    getCategories() {
      axios
        .get(this.$hostname + "/getcategories/" + this.business_id)
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(error => {
          error;
          alert("An error has occured");
        });
    },
    getItems() {
      axios
        .get(this.$hostname + "/getitems/" + this.business_id)
        .then(response => {
          this.items = response.data.data;
        })
        .catch(error => {
          error;
          alert("An error has occured");
        });
    },
    getTeams() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getteams/" + this.business_id)
        .then(response => {
          this.teams = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          error;
          alert("An error has occured");
        });
    }
  },
  mounted() {
    this.getBusinessDetails();
  }
};
</script>

<style>
#book-header {
  height: 70px;
  box-shadow: 0px 3px 6px #00000029;
}
#book-header img {
  margin-left: 46px;
  margin-top: 26px;
  height: 18px;
}
.btn-book,
.btn-book:focus,
.btn-book:hover {
  border-radius: 6px;
  background: #bd2532;
  height: 48px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 100px;
  margin-top: 20px;
}
#index {
  margin-top: 117px;
  padding-left: 143px;
  padding-right: 200px;
}
#book-activity {
  text-align: center;
}
#book-activity h3 {
  color: #37383b;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 73px;
}
@media (max-width: 768px) {
  #index {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  #book-activity h3 {
    font-size: 18px;
  }
}
</style>
