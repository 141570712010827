<template>
  <div id="app" data-app>
    <div
      v-if="
        $route.name !== 'Login' &&
          $route.name !== 'Signup' &&
          $route.name !== 'Book' &&
          $route.name !== 'CompeleteBooking' &&
          $route.name !== 'NotFound'
      "
    >
      <Navbar />
      <div class="wrapper">
        <div id="content-wrapper">
          <router-view />
        </div>
      </div>
    </div>
    <div v-else>
      <div id="login-wrapper">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/views/Layouts/Navbar.vue";

export default {
  name: "YamzitAppointments",
  components: {
    Navbar
  },
  data() {
    return {
      login_status: localStorage.getItem("login_status")
    };
  }
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  max-width: 100%;
  overflow-x: hidden;
}

#content-wrapper {
  background: #ffffff;
  padding: 100px 50px 50px 0px;
}

/* Start sidebar overlay css */
.b-sidebar {
  top: 11vh !important;
  height: 89vh !important;
}
.b-sidebar > .b-sidebar-body {
  background-color: #ffffff !important;
}
.b-sidebar > .b-sidebar-header {
  background-color: #ffffff !important;
}
/* End of sidebar overlay css */

table {
  width: 100%;
}
table thead {
  background: #ebebeb;
  height: 40px;
}
table thead tr th {
  padding: 10px;
}
table tbody tr {
  border-bottom: 1px solid silver;
}
table tbody tr td {
  height: 40px;
  padding: 10px;
  color: #3a3e46;
}
</style>
