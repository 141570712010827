<template>
  <div>
    <div id="spinner" v-if="isLoading">
      <LoadingSpinner />
    </div>
    <UserDetails :countries="countries" :business_types="business_types" />
  </div>
</template>

<script>
import UserDetails from "@/components/Auth/registerComponents/UserDetails.vue";
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "Signup",
  components: {
    UserDetails,
    LoadingSpinner
  },
  data() {
    return {
      countries: [],
      business_types: [],
      isLoading: false
    };
  },
  created() {
    this.getCountries();
    this.getBusinessTypes();
  },
  methods: {
    getCountries() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getCountries")
        .then(response => {
          this.isLoading = false;
          this.countries = response.data.data;
        })
        .catch(error => {
          this.isLoading = false;
          error;
          alert("An error has occured");
        });
    },
    getBusinessTypes() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getindustrytypes")
        .then(response => {
          this.isLoading = false;
          this.business_types = response.data.data;
        })
        .catch(error => {
          this.isLoading = false;
          error;
          alert("An error has occured");
        });
    }
  }
};
</script>

<style scoped>
#spinner {
  left: 50%;
}
</style>
