<template>
  <div class="items">
    <div class="row">
      <div class="col-4 col-md-4 col-lg-3">
        <div id="sidebar-wrapper">
          <Sidebar :routes="routes" />
        </div>
      </div>
      <div class="col-8 col-md-8 col-lg-9">
        <Items />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/Layouts/Sidebar";
import Items from "@/components/Items/Index";

export default {
  name: "items",
  components: { Sidebar, Items },
  data() {
    return {
      routes: [
        {
          link: "/items",
          title: "Items"
        },
        {
          link: "/categories",
          title: "Categories"
        },
        {
          link: "/disabled-items",
          title: "Disabled Items"
        }
        // ,{
        //   link: "/discounts",
        //   title: "Discounts"
        // }
      ]
    };
  }
};
</script>
