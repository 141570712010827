<template>
  <div>
    <transition name="modal-fade">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <Navbar />
        <section class="modal-body" id="modalDescription">
          <slot name="body">
            <div class="heading">
              <img
                src="@/assets/multiply.png"
                class="btn-close"
                @click="close"
              />
            </div>

            <div class="row" id="item-details">
              <div class="col-md-12">
                <h3>
                  <img
                    src="@/assets/left-arrow.png"
                    class="btn-close"
                    @click="close"
                  />
                  Items
                </h3>
                <div v-if="isLoaded">
                  <LoadingSpinner />
                </div>

                <table>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      @click="selectItem(item.id)"
                    >
                      <td id="icon">Bd</td>
                      <td id="item_name">{{ item.name }}</td>
                      <td id="cash">UGX {{ item.price | numberFormat }}</td>
                      <td id="selected">
                        <div v-if="current_item_id == item.id">
                          <img src="@/assets/tick.png" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row" id="next">
              <div class="col-md-12">
                <button class="btn btn-next" @click="saveItemChanges">
                  Save Changes
                </button>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "@/views/Layouts/Navbar.vue";
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  name: "success",
  components: {
    Navbar,
    LoadingSpinner
  },
  props: ["details", "items", "current_item_id"],
  data() {
    return {
      isLoaded: false,
      selected_item_id: null,
      business_id: localStorage.getItem("business_id")
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectItem(item_id) {
      this.selected_item_id = item_id;
      this.current_item_id = item_id;
    },
    saveItemChanges() {
      this.isLoaded = true;
      axios
        .put(this.$hostname + "/editappointment/" + this.details.id, {
          customer_id: this.details.customer_id,
          business_id: this.business_id,
          service_id: this.selected_item_id,
          team_id: this.details.team_id,
          date: this.details.date,
          time: this.details.time
        })
        .then(response => {
          console.log(response.data.message);
          this.$emit("close");
          location.reload();
          this.isLoaded = false;
        })
        .catch(error => {
          console.log(error);
          alert("An error has occured");
          this.isLoaded = false;
        });
    }
  }
};
</script>

<style scoped>
.modal {
  background: #ffffff;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  /* To make the modal full screen, remove the code below and vice varsa*/
  /* top: 58px; */
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow: auto;
}
.heading {
  padding-left: 20px;
  padding-bottom: 20px;
}
#item-details {
  margin: 0px 28% 0px 28%;
}
#item-details h3 {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  color: #707070;
}
#item-details h3 img {
  float: left;
  height: 14px;
  width: 17px;
}
#item-details table tr #icon {
  height: 40px;
  width: 40px;
  background: #9da2a6;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
#item-details table tr #item_name {
  color: #707070;
  font-size: 12px;
  font-weight: bold;
}
#item-details table tr #cash {
  font-size: 12px;
  text-align: right;
  color: #707070;
}
#item-details table tr #selected {
  width: 17px;
}
#item-details table tr #selected img {
  height: 12px;
}
#next {
  top: 60%;
  position: relative;
  text-align: center;
}
.btn-next {
  background: #be2532;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  width: 200px;
  margin-bottom: 100px;
}
</style>
