<template>
  <div class="categories">
    <div class="row">
      <div class="col-4 col-md-4 col-lg-3">
        <div id="sidebar-wrapper">
          <Sidebar :routes="routes" />
        </div>
      </div>
      <div class="col-8 col-md-8 col-lg-9">
        <Index :categories="categories" :isLoading="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/Layouts/Sidebar";
import axios from "axios";
import Index from "@/components/Categories/Index";

export default {
  name: "categories",
  components: { Sidebar, Index },
  data() {
    return {
      routes: [
        {
          link: "/items",
          title: "Items"
        },
        {
          link: "/categories",
          title: "Categories"
        },
        {
          link: "/disabled-items",
          title: "Disabled Items"
        }
        // ,{
        //   link: "/discounts",
        //   title: "Discounts"
        // }
      ],
      categories: [],
      business_id: localStorage.getItem("business_id"),
      isLoading: false
    };
  },
  methods: {
    getCategories() {
      this.isLoading = true;
      axios
        .get(this.$hostname + "/getcategories/" + this.business_id)
        .then(response => {
          this.categories = response.data.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          error;
          alert("An error has occured retrieving categories");
        });
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>
