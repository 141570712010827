import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Appointments/Home.vue";
import Login from "../views/Auth/Login.vue";
import Signup from "../views/Auth/Signup.vue";
import AppointmentHistory from "../views/Appointments/AppointmentHistory.vue";
import AppointmentRequests from "../views/Appointments/AppointmentRequests.vue";
import store from "../store";
import TeamMembers from "../views/Teams/TeamMembers";
import Permissions from "../views/Teams/Permissions";
import Items from "../views/Items/Items";
import Categories from "../views/Items/Categories";
import DisabledItems from "../views/Items/DisabledItems";
import Discounts from "../views/Items/Discounts";
// Routes for booking an appointment
import Index from "../views/CustomerBooking/Index.vue";
import BookingSuccess from "../components/CustomerBooking/BookingSuccess.vue";
import NotFound from "../views/Layouts/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: "/book/:business_name",
    name: "Book",
    component: Index,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: "/book/complete/:business_name",
    name: "CompeleteBooking",
    component: BookingSuccess,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: "/upcoming",
    name: "UpcomingAppointments",
    component: Home,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/history",
    name: "AppointmentHistory",
    component: AppointmentHistory,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/appointment-requests",
    name: "AppointmentRequests",
    component: AppointmentRequests,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/teams",
    name: "Teams",
    component: TeamMembers,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/items",
    name: "items",
    component: Items,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/disabled-items",
    name: "DisabledItems",
    component: DisabledItems,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/discounts",
    name: "Discounts",
    component: Discounts,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: "/*",
    name: "NotFound",
    component: NotFound,
    meta: {
      hideForAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresLogin)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
  // redirect straight to upcoming appoinments if login status is still true
  if (to.matched.some(route => route.meta.hideForAuth)) {
    if (store.getters.isLoggedIn) {
      next("/upcoming");
      return;
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
