<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light shadow-sm">
      <div class="container">
        <ToggleMenu />

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav mr-auto"></ul>

          <ul class="navbar-nav mx-auto">
            <li id="yamzit_logo"><img src="@/assets/logo_big.png" /></li>
          </ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" id="sign_out" @click="logout">
              <img src="@/assets/logout.svg" />
              Sign Out
            </li>
            <li class="nav-item" id="business_logo">
              <!-- <img
                src="https://workspace.yamzit.com/uploads/business/k3kk.png"
              /> -->
              <img :src="image_url + business_image" />
              {{ business_name }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import ToggleMenu from "@/views/Layouts/ToggleMenu";

export default {
  components: {
    ToggleMenu
  },
  data() {
    return {
      business_name: localStorage.getItem("business_name"),
      business_image: localStorage.getItem("business_image"),
      image_url: "https://workspace.yamzit.com/uploads/business/"
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("login_status");
      localStorage.removeItem("business_id");
      localStorage.removeItem("business_image");
      localStorage.removeItem("business_name");
      location.reload();
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: #ffffff;
  height: 11vh;
  box-shadow: 0px 3px 6px #0000004d;
}

#yamzit_logo img {
  height: 30px;
}
#sign_out img {
  height: 16px;
}
.ml-auto {
  height: 40px;
  line-height: 40px;
}
#sign_out {
  color: #101010;
  font-size: 12px;
  font-weight: bold;
  padding-right: 15px;
}
#business_logo {
  color: #707070;
  font-weight: bold;
  font-size: 12px;
  border-left: 1px solid #707070;
  padding-left: 15px;
}
#business_logo img {
  height: 30px;
  border-radius: 50%;
}
</style>
