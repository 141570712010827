<template>
  <div class="row login-container">
    <div class="col-md-12">
      <div id="login-header">
        <img src="@/assets/logo_big.png" />
      </div>
    </div>
    <div class="col-lg-12">
      <div v-if="isLoaded">
        <LoadingSpinner />
      </div>

      <form @submit.prevent="login()" autocomplete="off" method="POST">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="username" class="col-form-label text-md-left"
              >Phone Number</label
            >
            <input
              type="text"
              id="login-input"
              class="form-control"
              name="phone_number"
              v-model="phone_number"
              placeholder="Enter PhoneNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row" id="password-row">
          <div class="col-md-12">
            <label for="password" class="col-form-label text-md-left"
              >Password</label
            >
            <input
              id="password"
              type="password"
              class="form-control"
              name="password"
              v-model="password"
              placeholder="Enter Your Password"
              required
            />
            <a href="#">
              Forgot password?
            </a>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary" id="login-button">
              Sign In
            </button>
          </div>
        </div>
      </form>

      <div class="col-md-12" id="signup">
        <a href="/signup">
          Don’t have a Yamzit account?
          <span>Sign up</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/components/Utilities/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner
  },
  data() {
    return {
      phone_number: "",
      password: "",
      isLoaded: false
    };
  },
  methods: {
    login() {
      this.isLoaded = true;
      axios
        .post(this.$hostname + "/login", {
          phone_number: this.phone_number,
          pin: this.password
        })
        .then(response => {
          localStorage.setItem("login_status", true);
          this.$store.state.login_status = true;
          const business_id = response.data.data.id;
          const business_image = response.data.data.image;
          const business_name = response.data.data.name;
          localStorage.setItem("business_id", business_id);
          localStorage.setItem("business_image", business_image);
          localStorage.setItem("business_name", business_name);
          console.log(response.data.message);
          this.isLoaded = false;
          this.$router.push("/upcoming");
        })
        .catch(error => {
          console.log(error);
          this.isLoaded = false;
          alert("wrong credentials");
        });
    }
  }
};
</script>

<style scoped>
#login {
  max-width: 100%;
}
.login-container {
  margin-top: 7%;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 20px;
  margin-left: 35%;
  margin-right: 35%;
  margin-bottom: 6%;
  overflow: hidden;
}
#login-header {
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
}
#login-header img {
  height: 31px;
}
input {
  width: 100%;
}
#login-button {
  width: 100%;
  height: 38px;
  color: #fff;
  background-color: #bd2532;
  border: none;
  border-radius: 8px;
}
#login-input {
  border: solid #707070 1px;
}
#password {
  border: solid #707070 1px;
}
#signup a {
  color: #3d454c;
  font-size: 9px;
  margin-top: 17px;
  text-decoration: none;
}
#signup a span {
  color: #bd2532;
}
#password-row {
  margin-bottom: 50px;
}
#password-row a {
  color: #bd2532;
  text-align: right;
}
/* tablet */
@media (max-width: 768px) {
  .login-container {
    margin-top: 10%;
    margin-left: 24%;
    margin-right: 24%;
  }
}
/* mobile */
@media (max-width: 767px) {
  .login-container {
    margin-top: 10%;
    margin-left: 8%;
    margin-right: 8%;
  }
}
</style>
