<template>
  <div>
    <div class="business_logo">
      <div v-if="businessDetails.business_logo">
        <img
          :src="
            'https://workspace.yamzit.com/uploads/business/' +
              businessDetails.business_logo
          "
        />
      </div>
      <div v-else>
        <img src="@/assets/default_logo.png" />
      </div>
    </div>
    <div class="business_title">
      <h3>
        <div v-if="businessDetails.business_name">
          {{ businessDetails.business_name }}
        </div>
        <div v-else>
          Yamzit Appointments
        </div>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "logo",
  props: {
    businessDetails: {
      type: Object
    }
  }
};
</script>

<style scoped>
.business_logo {
  text-align: center;
  margin-bottom: 36px;
}
.business_logo img {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  border: 1px solid silver;
}
.business_title h3 {
  font-size: 29px;
  color: #37383b;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 768px) {
  .business_logo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .business_title h3 {
    font-size: 20px;
  }
}
</style>
