<template>
  <div class="teams">
    <div class="row">
      <div class="col-4 col-md-4 col-lg-3">
        <div id="sidebar-wrapper">
          <Sidebar :routes="routes" />
        </div>
      </div>
      <div class="col-8 col-md-8 col-lg-9">
        <Index />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/Layouts/Sidebar";
import Index from "@/components/Teams/Index";

export default {
  name: "teams",
  components: { Sidebar, Index },
  data() {
    return {
      routes: [
        {
          link: "/teams",
          title: "Team Members"
        }
        // ,{
        //   link: "/permissions",
        //   title: "Permissions"
        // }
      ]
    };
  }
};
</script>
